<template>
  <div>
    <div class="publicity">

      <div class="row ">
        <div class="col-12 col-sm-12 col-md-6 mt30">
          <el-carousel trigger="click"  >
            <el-carousel-item v-for="(item,index) in part.images" :key="index">
              <img :src="item" alt="">
            </el-carousel-item>
          </el-carousel>
        </div>

        <div class="col-12 col-sm-12 col-md-6 ">
          <div class="col-12 mt30">
            <p class="h2">{{part.title}}</p>

          </div>
          <div class="detail row text-center">
            <p class="h4 colff0"> 距离开奖还有</p>
            <p class="h1  colff0">   {{hr}}:{{min}}:{{sec}}</p>
          </div>
          <div class="col-12 buttons">
            <button class="btn" type="button" @click="handPush()">
              参与最新一期
            </button>
          </div>
        </div>

      </div>
      <div class="record">
        <div class="records" v-for="(item,index) in pate" :key="index">
          <img :src="item.image" alt="">
          <div class="name">
            <p class="h5">{{item.user}}</p>
            <p class="h6"><span>参与 <i>{{item.pate}}</i> 次</span> <span>{{item.time}}</span> </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {

  data(){
    return{

      id:'',
      part:[],

      pate:[],

      day: 0,
      hr: 0,
      min: 0,
      sec: 0,
      show:false,
      endTime:0,
    }
  },
  created() {
    this.id=this.$route.query.id
    this.bh=this.$route.query.bh


    let url='index/drawprize/wait_pro_details'
    this.axios({
      method: 'post',
      url,
      data: {
        id:this.id,
        lang:'ja',
        bh:this.bh
      },
      success: (res) => {
        this.part=res.data.date
        this.pate=res.data.pate

        this.endTime=this.part.downtime

        const end =Date.parse(this.endTime)
        // 定义当前时间戳
        const now = Date.parse(new Date())
        if(now>end){
          this.$router.push({
            path: '/publicity',
            query: {
              id:this.id,
              bh:this.bh,
            }
          })
        } else{
          this.countdown()
        }

      }
    })

  },
  methods:{
    handPush(){
      this.$router.push({
        path: '/particulars',
        query: {
          id: this.id
        }
      })

    },
    countdown () {
      console.log(1112)

      // 定义结束时间戳
      const end =Date.parse(this.endTime)
      // 定义当前时间戳
      const now = Date.parse(new Date())

      //做判断当倒计时结束时都为0
      if (now >= end) {
        this.day = 0
        this.hr = 0
        this.min = 0
        this.sec = 0
        this.show=true

        return
      }

      // 用结束时间减去当前时间获得倒计时时间戳
      const msec = end - now
      let day = parseInt(msec / 1000 / 60 / 60 / 24) //算出天数
      let hr = parseInt(msec / 1000 / 60 / 60 % 24)//算出小时数
      let min = parseInt(msec / 1000 / 60 % 60)//算出分钟数
      let sec = parseInt(msec / 1000 % 60)//算出秒数
      //给数据赋值
      this.day = day
      this.hr = hr > 9 ? hr : '0' + hr
      this.min = min > 9 ? min : '0' + min
      this.sec = sec > 9 ? sec : '0' + sec
      //定义this指向
      const that = this
      // 使用定时器 然后使用递归 让每一次函数能调用自己达到倒计时效果
      setTimeout(function () {
        that.countdown()
      }, 1000)

    },

  },
  mounted() {

  }

}
</script>

<style lang="less" scoped>
.publicity{
  background-color: #fff;
  color: #000000;
}
.mt30{
  margin-top: 30px;
}
.row{
  max-width: 1400px;
  margin: auto;
  .detail{
    margin-top: 30px;
    padding: 30px 10px 30px 0;

    background-color:#fbf3f0;
    .name{
      margin-left: 20px;
    }
    .col-3{
      position: relative;

      .zjz{
        width: 90px;
        height: 90px;
        position: absolute;
        top: -20px;
        left: -10px;
        border-radius:0;
      }
      img{
        width: 100px;
        height: 100px;

        border-radius: 50%;
      }
    }

  }
  .buttons{
    margin-top: 30px;
    text-align: center;
  }
  .btn{

    margin: auto;
    min-width: 200px;
    background-color: #fff;
    color:#ffffff;
    max-width: 200px;
    border-radius: 1.5rem;
    background-color: var(--colSubMain1);
    border:1px solid var(--colSubMain1);
    font-weight: bold;
    font-size: 1rem;


  }


}
.record{
  padding: 20px;
  max-width: 1400px;
  margin: auto;
  .records
  {
    display: flex;
    padding: 10px 0;
    margin-top: 20px;
    border-bottom: 1px solid var(--colSubMain2);
    img{
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
    }
    .name{
      margin-left: 20px;

      .h6{
        color: var(--colSubMain2);
      }
    }
  }

}

.el-carousel__item img{
  width: 100%;
  height: 100%;
}

::v-deep {
  .el-carousel__container{
    min-height: 350px;
  }
  @media screen and (max-width: 699px) and (min-width: 520px), (min-width: 1151px) {
    .el-carousel__container{
      min-height: 500px;
    }
  }
  @media screen and (max-width: 1000px) and (min-width: 700px) {
    .el-carousel__container{
      min-height: 500px;
    }
  }
  @media screen and (min-width: 1001px) {
    .el-carousel__container{
      min-height: 600px;
    }
  }
}

</style>