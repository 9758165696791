<template>
  <div class="footer row">
    <div class="container row">
  <div class="col-sm-2 col-sm-1"></div>
      <ul class="nav flex-column col-sm-4">
        <li class="nav-item">
          <p class=" col333 h3" >ショートカットリンク</p>
        </li>
        <li class="nav-item">
          <router-link to="/index" class=" col999" >トップページ</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/feedback" class="col999">連絡先</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/personal" class=" col999" >個人用ページ</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/new-hand" class=" col999" >初心者ガイドです</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.footer {
  border: 1px solid var(--colSubMain2);
  background-color: #fff;
  .container{
    max-width:1400px ;
    margin: auto;
    padding-bottom: 20px;
    ul{
      margin-left: 3rem;
      margin-top: 3rem;
      li{
        margin-top: 20px;
      }
    }
  }
}
</style>