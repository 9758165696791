<template>
  <div class="card " @click="handPush(details.bh,details.pid)">
    <img :src="details.image" class="card-img-top" alt="...">
    <div class="card-body">
      <p class="h5 col333 ov-h">
        {{details.title}}
      </p>
      <p class="h6 col333">
        <span>商品品番です：</span>  <i>{{details.bh}}</i>
      </p>

      <p class="h3 colff0" v-if="show===false">
        <i class=" el-icon-time"></i><span>すぐに賞を出す</span>
      </p>
      <p class="h2 colff0 text-center" v-if="show===false">
       {{hr}}:{{min}}:{{sec}}
      </p>
      <p v-if="show">
        <button type="button" class="btn btn-primary" @click="handPush(details.bh,details.pid)">詳細を調べます</button>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "card",
props:{
  details:[],
  startTime: String,
  endTime: String
},

  data () {
    return {
      day: 0,
      hr: 0,
      min: 0,
      sec: 0,
      show:false,
    };
  },
  created () {

  },
  mounted() {
    this.countdown()
  },
  methods: {
    handPush(bh,id){
      this.$router.push({
        path: '/darawn-details',
        query: {
          id,
          bh,
        }
      })
    },
    countdown () {
      // 定义结束时间戳
      const end =Date.parse(this.endTime)
      // 定义当前时间戳
      const now = Date.parse(new Date())

      // 做判断当倒计时结束时都为0
      if (now >= end) {
        this.day = 0
        this.hr = 0
        this.min = 0
        this.sec = 0
        this.show=true

        return
      }
      // 用结束时间减去当前时间获得倒计时时间戳
      const msec = end - now
      let day = parseInt(msec / 1000 / 60 / 60 / 24) //算出天数
      let hr = parseInt(msec / 1000 / 60 / 60 % 24)//算出小时数
      let min = parseInt(msec / 1000 / 60 % 60)//算出分钟数
      let sec = parseInt(msec / 1000 % 60)//算出秒数
      //给数据赋值
      this.day = day
      this.hr = hr > 9 ? hr : '0' + hr
      this.min = min > 9 ? min : '0' + min
      this.sec = sec > 9 ? sec : '0' + sec
      //定义this指向
      const that = this
      // 使用定时器 然后使用递归 让每一次函数能调用自己达到倒计时效果
      setTimeout(function () {
        that.countdown()
      }, 1000)

    }
  },


};
</script>

<style lang="less" scoped>
.card{
  margin-top: 30px;
}
.col-6{
  padding: 0 5px;
}
.colff0{
  color: var(--colSubMain1);
}
.ov-h{
  height: 3rem;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:2;
  overflow:hidden;
  text-overflow:ellipsis;
}
.btn{
  margin-top: 10px;

  width: 60%;
  background-color: #fff;
  color: var(--colSubMain1);
  border-radius: 0.25rem;
  border:1px solid var(--colSubMain1);
  font-weight: bold;
  font-size: 0.5rem;


}
</style>