<template>
<div class="cash ">
  <main class="row">
  <div class="col-12 col-md-4 col-lg-3 card">
    <p class="title col333 h4">邀请详情</p>
    <p class="money col333 h4">邀请好友数：{{cashList.tico.people}}</p>
    <p class="money col333 h4">获得推广费：{{cashList.tico.gold}}</p>
  </div>
  <div class="col-12 col-md-8 col-lg-9 ">
  <div class=" invitation">

    <img alt="" src="../../../public/img/yaoqing.png">
    <div class="invit">
      <p class="code col333 h6">{{cashList.user_br}}</p><el-button type="info" plain @click="copy(cashList)">复制邀请码</el-button>

    </div>
  </div>

  </div>
  </main>
  <main>
  <div class="back card col333">
    <p class="title col333 h4">邀请列表</p>
    <div class="row">
      <div class="col-3">
       头像
      </div>
      <div class="col-3 ">
       ユーザー
      </div>
      <div class="col-3 ">
        チャージ金额
      </div>
      <div class="col-3 ">
        获得返利
      </div>

    </div>
        <div class="row" v-for="(item,index) in cashList.data " :key="index">
          <div class="col-3">
            <img :src="item.image" alt="">
          </div>
          <div class="col-3 lh100">
            {{item.user}}
          </div>
          <div class="col-3 lh100">
            {{item.money}}
          </div>
          <div class="col-3 lh100">
            {{item.hgold}}
          </div>

        </div>
  </div>
  </main>
</div>
</template>

<script>
export default {
data(){
  return{
    ylikepo:[],
    cashList:[]
  }
},created() {
    this.axios({
      method: 'post',

      url: 'index/Ation/lnvite_list',
      data: {
        token: this.getCookie('token'),
      },
      success: (res) => {

        this.cashList= res.data
        console.log(this.cashList,11)
      }
    })
  },methods:{
    copy(row) {
      // 创建一个input框
      const input = document.createElement('input')
      // 将指定的DOM节点添加到body的末尾
      document.body.appendChild(input)
      // 设置input框的value值为复制的值
      const address = row.user_br
      input.setAttribute('value', address)
      // 选取文本域中的内容
      input.select()
      // copy的意思是拷贝当前选中内容到剪贴板
      // document.execCommand（）方法操纵可编辑内容区域的元素
      // 返回值为一个Boolean，如果是 false 则表示操作不被支持或未被启用
      if (document.execCommand('copy')) {
        document.execCommand('copy')
      }
      // 削除这个节点
      document.body.removeChild(input)
      // 弹窗：复制成功
      //
    },
  }
}
</script>

<style lang="less" scoped>
.cash{
  background-color: #fff;
  main{
    width: 100%;
    margin: auto;
    max-width: 1400px;
    .back{
      padding-top: 30px;
      margin-top: 30px;
    }
 .card{
   padding: 10px;
   .h4{
     height: 3rem;
     line-height: 3rem;
   }
 }
    .title{
      border-bottom: 1px solid var(--col999);
    }
  .invitation{
    position: relative;
    width: 100%;
    height: 100%;
    img{

      width: 100%;
      z-index: 0;

    }
    p{

    }

  }
    .invit{
      width: 100%;
      position: absolute;
      bottom:3%;
      left: 0;
      z-index: 999 !important;

       display: flex;
    }
    .code{
      border: 1px solid var(--col999);
      border-radius:7px;
      background-color: #fff;
      width: 60%;
      padding: 0 10px;
      line-height: 1.5rem;
      height: 1.5rem;
    }
    .el-button{
      height: 1.5rem;
      line-height: 1.5rem;
      margin-left: 20px;
      padding: 0 10px;
      line-height: 0.7rem;
    }
  }
  .back{
    .row{
      padding: 10px 0;
      text-align: center;

    }
    img{
      width: 100%;
      border-radius: 50%;
      max-width: 100px;
    }
  }
  .lh100{


    line-height: 100px;
  }
}
</style>