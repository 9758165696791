<template>
  <div>
    <div class="publicity">

      <div class="row ">
        <div class="col-12 col-sm-12 col-md-6 mt30">
          <el-carousel trigger="click" >
            <el-carousel-item v-for="(item,index) in part.images" :key="index">
              <img :src="item" alt="">
            </el-carousel-item>
          </el-carousel>
        </div>

        <div class="col-12 col-sm-12 col-md-6 ">
          <div class="col-12 mt30">
            <p class="h2">{{part.title}}</p>

          </div>
        <div class="detail row">

          <div class="col-12 row ">
              <div class="col-3 touxiang ">
                <img src="../../../public/img/zjz.png" alt="" class="zjz">
                <img src="https://t7.baidu.com/it/u=2621658848,3952322712&fm=193&f=GIF" alt="">
              </div>
          <div class="col-9">

            <div class="name">

            <p class="h4">
              获奖者：<i class="col119">{{prize.user}}</i>
            </p>
            <p class="h6">
              用户ID：<i>{{prize.user_id}}</i>
            </p>
            <p class="h6">
              本期编号：<i>{{part.bh}}</i>
            </p>
            <p class="h6">
              ラッキーナンバーです：<i class="colff0">{{prize.cjbh}}</i>
            </p>
            <p class="h6 col999">
             <i>{{prize.prizetime}}</i>
            </p>
            </div>

          </div>
        </div>
        </div>
          <div class="col-12 buttons">
            <button class="btn" type="button" @click="handPush()">
              参与最新一期
            </button>
          </div>
        </div>

      </div>
      <div class="record">
        <div class="records" v-for="(item,index) in pate" :key="index">
          <img :src="item.image" alt="">
          <div class="name">
            <p class="h5">{{item.user}}</p>
            <p class="h6"><span>参与 <i>{{item.pate}}</i> 次</span> <span>{{item.time}}</span> </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {

  data(){
    return{

      id:'',
      part:[],

      pate:[],
      prize:[],


    }
  },
  created() {
    this.id=this.$route.query.id
    this.bh=this.$route.query.bh


    let url='index/drawprize/pct_history'
    this.axios({
      method: 'post',
      url,
      data: {
        id:this.id,
        lang:'ja',
        bh:this.bh
      },
      success: (res) => {
       
        this.part=res.data.date
        this.pate=res.data.pate
        this.prize=res.data.prize



      }
    })

  },
  methods:{
    handPush(){
      this.$router.push({
        path: '/particulars',
        query: {
          id: this.id
        }
      })

    },

  }

}
</script>

<style lang="less" scoped>
.publicity{
  background-color: #fff;
  color: #000000;
}
.mt30{
  margin-top: 30px;
}
.row{
  max-width: 1400px;
  margin: auto;
 .detail{
   margin-top: 30px;
    padding: 30px 10px 30px 0;

   background-color:#fbf3f0;
   .name{
     margin-left: 20px;
   }
  .col-3{
    position: relative;

    .zjz{
      width: 90px;
      height: 90px;
      position: absolute;
      top: -20px;
      left: -10px;
      border-radius:0;
    }
    img{
     width: 100px;
      height: 100px;

      border-radius: 50%;
    }
  }

 }
  .buttons{
    margin-top: 30px;
   text-align: center;
  }
  .btn{

    margin: auto;
    min-width: 200px;
    background-color: #fff;
    color:#ffffff;
    max-width: 200px;
    border-radius: 1.5rem;
    background-color: var(--colSubMain1);
    border:1px solid var(--colSubMain1);
    font-weight: bold;
    font-size: 1rem;


  }


}
.record{
  padding: 20px;
  max-width: 1400px;
  margin: auto;
  .records
  {
    display: flex;
    padding: 10px 0;
    margin-top: 20px;
    border-bottom: 1px solid var(--colSubMain2);
    img{
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
    }
    .name{
      margin-left: 20px;

      .h6{
        color: var(--colSubMain2);
      }
    }
  }

}

.el-carousel__item img{
  width: 100%;
  height: 100%;
}

::v-deep {
  .el-carousel__container{
    min-height: 350px;
  }
  @media screen and (max-width: 699px) and (min-width: 520px), (min-width: 1151px) {
    .el-carousel__container{
      min-height: 500px;
    }
  }
  @media screen and (max-width: 1000px) and (min-width: 700px) {
    .el-carousel__container{
      min-height: 500px;
    }
  }
  @media screen and (min-width: 1001px) {
    .el-carousel__container{
      min-height: 600px;
    }
  }
}

</style>