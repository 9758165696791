<template>
  <div class="cart">
    <h2>结算订单</h2>
    <div class="row shop">

      <div class="col-2 max-h100">
        <img src="https://gw.alicdn.com/imgextra/i3/3901361754/O1CN01kX54nj1OpNPa1oo64_!!3901361754.jpg_Q75.jpg_.webp" alt="" class="col-4">

      </div>
      <div class="col-1"></div>
      <div class="col-9 row">

              <h5 class="h6 ov-h">{{ details.title}}</h5>
              <h5 class="h6 col999">总需人数：<i class="colffc">{{ details.needed}}</i>   </h5>
               <h5 class="h6 col999">剩余人数：<span class="col119">{{maxs}}</span> </h5>
          <el-input-number v-model="num" :min="1" :max="maxs"></el-input-number>


      </div>
      <ul>
        <li @click="num=5">5</li>
        <li  @click="num=10">10</li>
        <li @click="num=20" >20</li>
        <li  @click="num=50">50</li>
        <li  @click="num=maxs">包尾</li>
      </ul>
    </div>
      <div class="row">

      </div>
    <div class="clearing row">
        <div class="col-6 col-sm-8">
          <i class="col999">合计金货：</i> <i class="h3 ">{{num}} </i>
        </div>
      <div class="col-6 col-sm-4">
        <button type="button" @click="handSubmit()">提出订单</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      num:'',
      checked:false,
      id:"",
      maxs:0,
      details:[],
    }
  } ,created() {
    this.id=this.$route.query.id
    let url='index/Details/details_tent'+'?lang'+'='+'ja'+'&id'+'='+this.id
    this.axios({
      method: 'get',
      url,
      data: {},
      success: (res) => {
        this.details=res.data.date
        this.maxs=(this.details.needed-this.details.part_son)*1
      }
    })

  },
  methods:{
    handSubmit(){
      this.axios({
        method: 'post',
        url:'index/Details/pate_cj',
        data: {
          token:this.getCookie('token'),
          pid:this.id,
          ment:this.num,
          lang_nub:2,
        },
        success: (res) => {
         if(res.data.type==='503'){
           this.$message({
             type: 'warning',
             message:res.data.date +'请チャージ' ,
             onClose: ()=>{
               this.push('/skype')
             }
           })
         }else if(res.data.type){
           this.$message({
             type: 'warning',
             message:res.data.date,
             onClose: ()=>{
               this.push('/index')
             }
           })
         }



        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.cart{
  background-color: #fff;
  color: #000000;
  h2{
    font-size: 1.5rem;
    margin-top: 20px;
    color: #000000;

  }
  .ov-h{
    line-height: 1.5rem;
    height: 3rem;
    color: #000000;
    display:-webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2;
    overflow:hidden;
    text-overflow:ellipsis;

  }
  .max-h100{
    max-height: 100px;


  }

  .shop{

    margin:20px auto;
    max-width: 1400px;
    width: 90%;
    padding-bottom: 50px;
    border-bottom:1px solid var(--colSubMain2);
    ::v-deep .el-input-number{

      width: 100%;
      max-width: 200px;
      margin-top: 20px;
    }
  }
  ul{
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 500px;
    margin-top: 30px;
    li{
      width: 50px;
      height: 2rem;
      line-height: 2rem;
      text-align: center;
      color: #ffffff;
      border-radius: 5px;
      background-color: var(--colSubMain1);

    }
  }
  .clearing{
    width: 100%;
    padding:0 5% 5% 20px;
    max-width: 1400px;
    line-height: 40px;

    border-bottom:1px solid var(--colSubMain2) ;
    .col-sm-8{
      text-align: right;



    }
    button{
      width: 120px;
      line-height: 40px;
      border-radius: 20px;
      background-color: var(--colSubMain1);
      color: #ffffff;
    }

  }
  .col-2{

    img{
      width: 100%;
      max-width: 100px;
      min-width: 60px;
    }
  }

}
</style>