<template>
  <nav class="navbar navbar-expand-lg navbar-light ">
    <div class="container-fluid navbar-left">
      <router-link to="/index" class="navbar-brand" href="#">1ドルで購入</router-link>
      <div class="fx ">
        <div class="d-block d-sm-block d-md-block d-lg-none">
          <div class="icon">
            <router-link to="/information">
              <el-badge :value="profile" class="item">
                <img src="../../public/img/lingdang.png" alt="" class="pointer-col " >

              </el-badge>
            </router-link>
            <router-link to="/cart">
              <img src="../../public/img/gouwuche.png" alt="" class="pointer-col ">
            </router-link>
            <router-link to="/personal">
              <img src="../../public/img/wode.png" alt="" class="pointer-col ">
            </router-link>
          </div>

        </div>
        <button class="navbar-toggler " type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        </div>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link to="/index" class="nav-link active" aria-current="page" href="#">トップページ</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/classify" class="nav-link active" aria-current="page" href="#">分类</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/drawn" class="nav-link active" aria-current="page" href="#">すぐに賞を出す</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/announce" class="nav-link active" aria-current="page" href="#">中奖揭晓</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/personal" class="nav-link active" aria-current="page" href="#">個人用ページ</router-link>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              切换语言{{en}}
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li @click="handEn('ja')"><a class="dropdown-item" href="#">日语</a></li>
              <li @click="handEn('en')"><a class="dropdown-item" href="#">英语</a></li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              设置
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li @click="handQuit"><a class="dropdown-item" href="#">退出登录</a></li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="icon d-none d-lg-block d-xl-block">
        <router-link to="/information">
          <el-badge :value="profile" class="item">
            <img src="../../public/img/lingdang.png" alt="" class="pointer-col ">
          </el-badge>
        </router-link>
        <router-link to="/cart">
          <img src="../../public/img/gouwuche.png" alt="" class="pointer-col ">
        </router-link>
        <router-link to="/personal">
        <img src="../../public/img/wode.png" alt="" class="pointer-col ">
        </router-link>
      </div>
    </div>

  </nav>
</template>

<script>
export default {

  data() {
    return {
          en:'',
      profile:0,
    }
    },
  created() {
    let token= this.getCookie('token')
    if(token!==null){
      this.axios({
        method: 'post',
        url: 'index/Drawprize/not_read',
        data: {
          token,
        },
        success: (res) => {
          this.profile = res.data.data
        }
      })
    }

  },
  methods:{
        handEn(ens){
      this.en=ens
      this.setCookie("en", ens);
    },
    handQuit(){
          let i=null
      this.setCookie("token", i);
      this.$router.push('/login')
    }
  }
};
</script>

<style lang="less" scoped>
.navbar{
  max-width: 100%;
  background-color:#f9fafb;

}
.icon{
  line-height: 40px;

  img{
    margin: 0 10px;
    width: 1.5rem;
  }
}
::v-deep{
  .el-badge__content.is-fixed{
    top:10px;
    right: 20px;

  }
}
</style>
