<template>
  <div class="app">
    <div class="jumbotron ">

      <div class=" head-portrait text-center">
        <img :src="profile.image" alt="..." class="img-thumbnail">
        <h2>{{ profile.user }}</h2>
        <p class="h6">E-mail：{{ profile.emli }}</p>

        <p class="h6">id：{{ profile.br }}</p>

        <router-link to="/compile">
          <button class="btn btn-primary">個人情報の編集</button>

        </router-link>
        <h3 class="text-warning">
          <img alt="" src="../../../public/img/cz.png">
          金货：{{ profile.gold }}
        </h3>
      </div>

    </div>
    <main class="row ">

      <div class="container mt-3 col-12 col-md-4">

        <router-link to="/skype">
          <p class="h6"><img alt="" src="../../../public/img/chongzhi.png"> <i>チャージ</i></p>

        </router-link>

      </div>
      <div class="container mt-3 col-12 col-md-4">

        <router-link to="/purchase">
          <p class="h6"><img alt="" src="../../../public/img/jilu.png"> <i>参加記録です</i></p>

        </router-link>

      </div>
      <div class="container mt-3 col-12 col-md-4">

        <router-link to="/recharge-record">

          <p class="h6"><img alt="" src="../../../public/img/congzhijilu.png"> <i>チャージ記録です</i></p>
        </router-link>

      </div>
      <div class="container mt-3 col-12 col-md-4">

        <router-link to="/winning">
          <p class="h6"><img alt="" src="../../../public/img/zhongjiangjilu.png"> <i>当選記録</i></p>
        </router-link>
      </div>
<!--      <div class="container mt-3 col-12 col-md-4">-->

<!--        <router-link to="/address">-->
<!--          <p class="h6"><img alt="" src="../../../public/img/souhuodizhi.png"> <i>收货地址</i></p>-->
<!--        </router-link>-->

<!--      </div>-->
      <div class="container mt-3 col-12 col-md-4">

        <router-link to="/cash-back">

          <p class="h6"><img alt="" src="../../../public/img/yaoqingfanli.png"> <i>懸賞付きです</i></p>
        </router-link>

      </div>
      <div class="container mt-3 col-12 col-md-4">

        <router-link to="/code">

          <p class="h6"><img alt="" src="../../../public/img/fankui.png"> <i>招待コードの記入</i></p>
        </router-link>

      </div>


    </main>
  </div>


</template>

<script>
export default {
  data() {
    return {
      profile: [],
    }
  },
  created() {

    this.axios({
      method: 'post',

      url: 'index/user/profile',
      data: {
        token: this.getCookie('token'),
      },
      success: (res) => {
        this.profile = res.data.data

      }
    })
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.app {
  padding: 0;
  margin: 0;
  background-color: #fff;

  .jumbotron {
    background-color: #fff;
    position: relative;
    width: 100%;
    overflow: hidden;
    background-image: url('../../../public/img/biejin.png');
    background-size: cover;
  }

  .btn {
    margin-top: 1rem;
  }

  .head-portrait {
    margin-top: 10%;

    .img-thumbnail {
      max-width: 100px;
      height: 100px;
      border-radius: 50%;
    }

    .h6 {
      color: #ffffff;
      margin-top: 1rem;
    }

    h2 {
      color: #fff;
      font-size: 1.5rem;
      margin-top: 2rem;

    }

    h3 {
      margin: 2rem 0;

      img {
        max-width: 2rem;
      }
    }

  }

  main {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto 10% ;
    color: #000;
    background-color: #fff;

    .container {
      padding-left: 10%;
      border-bottom: 1px solid var(--colSubMain2);
    }

    img {
      max-width: 1.5rem;
    }
  }
}

</style>