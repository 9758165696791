<template>
<div class="inquire">
        <p class="h2 col333">チャージ結果です</p>

      <div  v-if="msg===0">
        <el-result icon="success" title="チャージ成功です" subTitle="個人センターに行きます">
          <div slot="extra">
            <el-button type="primary" size="medium" @click="handPush()">個人用ページ</el-button>
          </div>
        </el-result>
      </div>
  <div  v-if="msg===1">
      <el-result icon="warning" title="チャージ错误" subTitle="请重试">
        <div slot="extra">
          <el-button type="primary" size="medium">重新チャージ</el-button>
        </div>
      </el-result>
  </div>
</div>
</template>

<script>
export default {

  data () {
    return {
      result: 0,
      msg:0,
    }
  },
  created() {

  },
  methods: {
      handCallback(){
        let orderId=this.$route.query.orderId
        let token=this.$route.query.token
        let success=this.$route.query.success
        let paymentId=this.$route.query.paymentId
        let  PayerID=this.$route.query.PayerID
        this.axios({
          method: 'get',
          url:'index/paypal/Callback',
          data: {
            orderId,
            token,
            success,
            paymentId,
            PayerID
          },
          success: (res) => {
          
            if(res.data.error==='0'){
              this.msg=0
            }else{
              this.msg=1
            }

          }
        })
      },
    handPush(){
      this.$router.push({
        path: '/personal',
      })
    }
  },

}
</script>

<style lang="less" scoped>

.inquire{
  background-color: #fff;
  .h2{
      margin-top: 30px;
  }
}
</style>