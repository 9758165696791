<template>
<div class="invit">
  <main>
    <h2 class="h2 col333">招待コードの記入 </h2>
    <div class="code">
      <el-form >
      <el-form-item label="截获码" prop="name">
      <el-input v-model="accept"></el-input>
      </el-form-item>
      </el-form>
      <button  type="button" @click="handPUsh()">提出</button>
    </div>
    <img src="../../../public/img/banner.jpg" alt="">
  </main>
</div>
</template>

<script>
export default {
  data(){
    return{
      accept:'',
    }
  },methods:{
    handPUsh(){
      if(this.accept!==''){
        this.axios({
          method: 'post',

          url: 'index/Ation/upollew',
          data: {
            token: this.getCookie('token'),
            accept:this.accept,
            lang_nub:2,
          },
          success: (res) => {
            if(res.data.msg===200){
              this.$router.push('/personal')
            }
          }
        })
      }

    }
  }
}
</script>

<style lang="less" scoped>


.invit{
  width: 100%;
  background-color: #fff;
  main{
    width: 100%;
    max-width: 1400px;
    margin: auto;
    h2{
      text-align: center;
      margin: 30px 0;
    }
    .code{
      width: 20%;
      min-width: 450px;
      max-width: 450px;
      margin: auto;
      text-align: center;
    }
    img{
      width: 100%;
    }
    button{
      width: 60px;
      height: 40px;
      background-color: var(--colSubMain1);
      color: #ffffff;
      font-size: 1rem;
      border-radius: 5px;
      margin: 30px 0;

    }
  }
}
</style>