// import引入组件

import Login from "@/page/login/login.vue";
import Personal from "@/page/personal/personal.vue";
import Compile from "@/page/compile/compile.vue";
import Skype from "@/page/skype/skype.vue";
import RechargeRecord from "@/page/recharge-record/recharge-record.vue";
import Purchase from "@/page/purchase/purchase.vue";
import Winning from "@/page/winning/winning.vue";
import Address from "@/page/address/address.vue";
import Addressing from "@/page/addressing/addressing.vue";
import Order from "@/page/order/order.vue";
import Cart from "@/page/cart/cart.vue";
import Clearing from "@/page/clearing/clearing.vue";
import Particulars from "@/page/particulars/particulars.vue";
import Register from "@/page/register/register.vue";
import Classify from "@/page/classify/classify.vue";
import Inquire from "@/page/inquire.vue";
import NewHand from "@/page/new-hand/new-hand.vue";
import Announce from "@/page/announce/announce.vue";
import Publicity from "@/page/ publicity/publicity.vue";
import Feedback from "@/page/ feedback/feedback.vue";
import Information from "@/page/information/information.vue";
import Drawn from "@/page/drawn/drawn.vue";
import DarawnDetails from "@/page/darawn-details/darawn-details.vue";
import CashBack from "@/page/cashback/cash-back.vue";
import Code from "@/page/code/code.vue";




export default {
    routes: [
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/personal',
            name: 'personal',
            component: Personal

        },
        {
            path: '/compile',
            name: 'compile',
            component:Compile

        },
        {
            path: '/skype',
            name: 'skype',
            component:Skype

        },
        {
            path: '/recharge-record',
            name: 'recharge-record',
            component:RechargeRecord

        },
        {
            path: '/purchase',
            name: 'purchase',
            component:Purchase

        },
        {
            path: '/winning',
            name: 'winning',
            component:Winning

        },{
            path: '/address',
            name:'address',
            component: Address
        },{
            path: '/addressing',
            name:'/addressing',
            component: Addressing
        },
        {
            path: '/order',
            name:'/order',
            component: Order
        },{
            path:'/cart',
            name:'cart',
            component: Cart
        },{
            path:'/clearing',
            name:'clearing',
            component: Clearing
        },{
        path: '/particulars',
            name:'particulars',
        component:Particulars
        },
        {
            path: '/register',
            name:'register',
            component:Register},
        {
            path: '/classify',
            name:'classify',
            component:Classify},
        {
            path: '/inquire',
            name:'inquire',
            component:Inquire},
        {
            path: '/new-hand',
            name:'new-hand',
            component:NewHand},
        {

            path: '/announce',
            name:'announce',
            component:Announce}
        ,
        {

            path: '/publicity',
            name:'publicity',
            component:Publicity},
        {   path:'/feedback',
            name:'feedback',
            component: Feedback},
        {   path: '/information',
            name:'information',
            component: Information},
        {
            path:'/drawn',
            name:'drawn',
            component: Drawn
        },
        {   path: '/darawn-details',
            name:'darawn-details',
            component: DarawnDetails
        }
        ,
        {   path: '/cash-back',
            name:'cash-back',
            component: CashBack},
        {   path: '/code',
            name:'code',
            component: Code},

    ]
}
