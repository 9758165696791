<template>
  <section class="login">
    <div class="visible-xs"></div>
    <p class="h2">注册</p>
    <div class="main">

      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="ユーザー" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item
            prop="email"
            label="邮箱"
            :rules="[
      { required: true, message: '请输入邮箱地址', trigger: 'blur' },
      { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
    ]"
        >
          <el-input v-model="ruleForm.email"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="pass">
          <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="邀请码" >
          <el-input type="name" v-model="ruleForm.code" autocomplete="off"></el-input>
        </el-form-item>
        <div class="btn">
          <button @click="submitForm('ruleForm')" type="button">注册</button>

        </div>


      </el-form>

    </div>
    <div class="visible-xs"></div>

  </section>

</template><script>
export default {
  data() {

    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        pass: '12345678',
        checkPass: '12345678',
        name: 'qweasd789',
        email:'w31520571703@qq.com',
        code:''
      },
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 3, max: 20, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        pass: [
          { required: true,validator: validatePass, trigger: 'blur', }
        ],
        checkPass: [
          { required: true,validator: validatePass2, trigger: 'blur' }
        ],

      }
    };
  },
  created() {
    let codes=this.$route.query.codes
    console.log(codes)
    if(codes.length>0){
      this.ruleForm.code=codes

    }

  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          

          this.axios({
            method: 'post',

            url: 'index/user/register',
            data: {
              user:this.ruleForm.name,
              pwd:this.ruleForm.pass,
              emli:this.ruleForm.email,
              accept:this.ruleForm.code

            },
            success: (res)=>{
               
                if(res.data.msg==='200'){

                  this.$message({
                    type: 'dark',
                    message: '注册成功,前往登录',

                  })
                  this.$router.push('/login')
                }else{
                  this.$message({
                    type: 'warning',
                    message: res.data.date,

                  })
                }
            }
          }, )
        } 
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>
<style lang="less" scoped>

// slot style
.in-index {
  color: var(--colMain);
  font-weight: 700;
}
.login {
  background-color: #fff;
  padding-top: 30px;
  color: #000000;
  .visible-xs{
    height: 100px;
  }
  .main{
    max-width: 400px;

    text-align: center;
    padding-top: 30px;
    ::v-deep .el-form-item {
      width: 400px;
      margin:20px auto;
      display: flex;
      label{
        width: 80px !important;
        margin-left: 20px;
      }
      .el-form-item__content{
        margin-left: 5px !important;
        width: 220px;
      }
      .el-input{
        width: 240px;
      }
    }
    .btn{
      margin-top: 50px;
    }
    button{
      width: 60px;
      height: 40px;
      background-color: var(--colSubMain1);
      color: #ffffff;
      font-size: 1rem;
      border-radius: 5px;

    }
    i{
      margin-left: 20px;

    }
    i:hover{
      color: var(--colSubMain1);

    }
  }
}
</style>
