<template>
<div class="feedback">
              <h2 class="col333">联系我们</h2>
  <div class="tile" v-html="feedback.content">
  </div>
  <div class="tile">
    <p class="h4">
      <i class="h4">{{feedback.email}}</i></p>



  </div>


</div>
</template>

<script>
export default {
  data(){
    return{
      feedback:[],
    }
  },created() {
    this.axios({
      method: 'post',
      url: 'index/user/user_opinion',
      data: {
        lang:'ja',
        lang_nub:2,
      },
      success: (res) => {

        this.feedback = res.data.date
      
      }
    })
  }
}
</script>

<style lang="less" scoped>
  .feedback{
    background-color: #fff;
    h2{
      margin-top: 30px;
      font-size: 2rem;

    }
    .tile{
      width: 80%;
      margin: auto;
      padding-top: 20px;
      color: #333333;
      p{
        margin:auto ;
        width: 400px;

      }

    i{
      margin-left: 30px;
    }
      img{
        margin-top: 30px;
        width: 100%;
      }
    }
  }
</style>