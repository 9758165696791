<template>
  <div id="shortcut">
<div class="shortcut">
  <div class="row">



    <div class=" col-3 cards">
      <router-link to="/classify">

      <img  src="../../../../public/img/fenlei.png" class="rounded mx-auto d-block" alt="...">
      <p class="h6">商品分類</p>
      </router-link>

    </div>


    <div class="col-3 cards">
      <router-link to="/drawn">

      <img  src="../../../../public/img/xs.png" class="rounded mx-auto d-block" alt="...">
      <p class="h6">すぐに賞を出す</p>
      </router-link>

    </div>


    <div class=" col-3 cards">
      <router-link to="/announce">

      <img  src="../../../../public/img/zongjiang.png" class="rounded mx-auto d-block" alt="...">
      <p class="h6">当選発表です</p>
      </router-link>

    </div>


    <div class="col-3 cards">
      <router-link to="/personal">

      <img  src="../../../../public/img/my.png" class="rounded mx-auto d-block" alt="...">
      <p class="h6">個人用ページ</p>
      </router-link>

    </div>


  </div>
</div>
</div>
</template>

<script>
export default {
  name: "shortcut"
}
</script>

<style lang="less" scoped>
#shortcut{
  width: 100%;
  .shortcut{
    max-width: 1000px;
    margin:0 auto;
  .cards{
    text-align: center;
    padding: 0;
    img{
      border-radius: 50%;
      max-width: 60px;
      max-height: 60px;
      margin:20px auto;
    }
    p{
      color: #000000;
    }
  }
  }

}
</style>