<template>
  <section>
    <banner></banner>
    <shortcut></shortcut>
    <message></message>
    <div class="classifys row">
      <div class="col-3 pointer " @click="handChoice(1)">
        <h6 :class="[classifys===1?'active':'']">
          人気商品

        </h6>

        <h5 class="col-12" v-show="classifys===1"></h5>

      </div>
      <div class="col-3 pointer " @click="handChoice(2)">
        <h6
            :class="[classifys===2?'active':'']">
          最新の商品

        </h6>
        <h5 class="col-12"  v-show="classifys===2"></h5>

      </div>
      <div class="col-3 pointer " @click="handChoice(3)">
        <h6 :class="[classifys===3?'active':'']">
          満員間近

        </h6>
        <h5 class="col-12" v-show="classifys===3"></h5>

      </div>
      <div class="col-3 pointer ">
        <div class="sort" >


          <p class="h5" :class="[classifys===4?'active':'']" @click="handChoice(4)" >熱が高いです</p>
          <p class="h5" :class="[classifys===5?'active':'']" @click="handChoice(5)">熱が低いです</p>



        </div>
        <h5 class="col-12" v-show="classifys===4"></h5>
        <h5 class="col-12" v-show="classifys===5"></h5>


      </div>

    </div>
    <div class="hot row">
      <div class="col-6 col-md-4 col-lg-3" v-for="item in shopList" :Key="item.id">
        <card :details="item"></card>

      </div>




    </div>
  </section>
</template>

<script>
 
import banner from "@/components/banner.vue";
import shortcut from "@/page/index/components/shortcut.vue";
import card from "@/components/card.vue";
import message from "@/page/index/components/message.vue";
  export default {
  components:{banner,shortcut,card,message},
    data(){
    return{
      classifys:1,
      shopList:[],
      en:'ja',

    }
    },
    created() {
    this.handKind()



    },
    methods:{
    handChoice(q){
        this.classifys=q
        this.handKind()

      },
      handKind(){
        let i=''
        if( this.classifys===4){
          i='sive'
        }else if(this.classifys===5){
          i='cheap'
        }else{
          i=this.classifys
        }
        let url='index/index/product_recommend'+'?lang'+'='+'ja'+'&choice'+'='+i
        this.axios({
          method: 'get',
          url,
          data: {},
          success: (res) => {

            this.shopList=res.data.date



          }
        })
      },
    }
  }
</script>

<style lang="less" scoped>
.banner{
  height: 33.3%;
}
section{
  height: 100%;
  background-color: #fff;
  .classifys{

    margin: auto;
    background-color: var(--colSubMain2);
    width: 95%;

    max-width: 1400px;
    height: 3rem;
    .sort{

      .h5{
        font-size: 0.4rem;
        color: #000000;
        line-height: 1.5rem;
        margin: 0;
      }
    }
    .active{
      color: var(--colSubMain1) !important;

    }
    .col-3{
      position: relative;
      text-align: center;
      padding: 0 !important;


      h6{
        line-height: 3rem;
        color: #000;
      }
      .h6{
        line-height: 3rem;
        color: #000;
        margin: 0;

      }
      h5{
        position: absolute;
        width: 100%;
        height: 0.2rem;
        background-color: var(--colSubMain1);
        margin: 0;

      }

    }


  }
  .hot{
    max-width: 1200px;
    margin: auto;
  }

}
</style>