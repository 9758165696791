<template>
  <div class="card " >
    <img :src="details.image" class="card-img-top" alt="..." @click="handPush()">
    <div class="card-body">
        <h5 class="card-title " @click="handPush()">{{details.title}}</h5>
        <el-progress :text-inside="true" :stroke-width="16" :percentage="perce" :color="customColor"></el-progress>
      <div class="row mt20">
        <div class="col-6">
        <button class="btn" type="button" @click="handPart()">
          購入する
        </button>
        </div>
        <div class="col-6">
          <img src="../../public/img/cart.png" alt="" @click="handShopping()">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "card",
  props:{
    details:[]
  },
  data(){
    return{
      customColor:'#ffc107',
      perce:0,

    }
  },created() {
      let i=(this.details.part_son/this.details.needed)*100
      this.perce=Math.trunc(i)
  },methods:{
    handPush(){
      this.$router.push({
        path: '/particulars',
        query: {
          id: this.details.id,
        }
      })
    },
    handShopping(){
      let token=this.getCookie('token')
      if(token===null){
        this.$router.push('/login')
      }
      this.axios({
        method: 'post',
        url: '/index/Details/shopping',
        data: {
          token,
          pid:this.details.id,
          number:1,
          lang_nub:2,

        },
        success: (res)=>{
          if(res.data.msg!==200){
            this.$message({
              type: 'warning',
              message: res.data.date,
            })
          }else{
            this.$message({
              type: 'success',
              message: res.data.date,
            })
          }
        }
      } )
    },
    handPart(){
      this.$router.push({
        path: '/clearing',
        query: {
          id: this.details.id
        }
      })
    },
  }
}
</script>

<style  lang="less" scoped>
.card{
  margin-top: 1.5rem;

  .progress{
    height: 0.8rem;
    font-size: 0.25rem;
    margin-bottom: 1rem;
  }
  h5{
    line-height: 1.5rem;
    height: 3rem;
    color: #000000;
    display:-webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2;
    overflow:hidden;
    text-overflow:ellipsis;

  }

  .btn{
      width: 60%;
    min-width: 60px;
    background-color: #fff;
    color: var(--colSubMain1);
    border-radius: 0.25rem;
    border:1px solid var(--colSubMain1);
    font-weight: bold;
    font-size: 0.5rem;


  }
  .col-6{
    text-align: right;
    img{
      width: 2rem;

    }
  }
  .mt20{
    margin-top: 20px;
  }

}
</style>