<template>
  <div class="purchase">
    <h2>收货地址</h2>
    <div class="hot row">
      <div class="col-12 col-md-6 col-lg-4" v-for="(item,index) in addressList" :key="index">
        <div class="card" >
          <div class="imgs">
            <img src="../../../public/img/honglan.png" alt="">
          </div>
                <div class="card-body">
                  <h5><img src="../../../public/img/yonghu.png" alt=""> {{item.name}}{{item.nature}} ,{{item.phone}}</h5>

                  <div class=" row"> <div class="col-3">地址：</div>
                    <div class="col-9">

                    <i class="ov-h">{{item.province}}{{item.city}}{{item.district}}{{item.detailed}}</i>
                  </div>

                  </div>

          <div class="btn">
            <i>削除</i>
            <i>修改</i>


          </div>
        <div class="btn">
          <span>默认收货地址</span>
          <span>默认账单地址 </span>
        </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 col-lg-3 images text-center">

                    <img src="../../../public/img/jiahao.png" class="img-thumbnail" alt="" @click="handPush(0)">

      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      addressList:[]
    }
  },
  created() {
    this.axios({

      method: 'post',
      url:'index/user/user_address',
      data: {
        token:this.getCookie('token'),

      },
      success: (res) => {
     
        this.addressList=res.data.date


      }
    })
  },
  methods:{
    handPush(i){
      this.$router.push({
        path: '/addressing',
        query: {
          id: i
        }
      })
    }

  }
}
</script>

<style lang="less" scoped>
.purchase{
  background-color: #fff;
  padding-top: 2rem;
  h2{
    font-size: 2rem;
    color: #000000;
  }
  .hot{
    max-width: 1200px;
    margin: auto;
  }
  .card{
    margin-top: 1.5rem;
    .imgs{
      width: 100%;
      height: 10px;
      overflow: hidden;
      position: relative;
      img{
        width: 200%;
        position: absolute;
        top: -20%;
        left: -20%;

      }

    }
    .progress{
      height: 0.8rem;
      font-size: 0.25rem;
    }
    h4{
      color: #ffcd39;
    }
    h5{
      line-height: 1.5rem;
      height: 1.5rem;
      overflow: hidden;
      color: #000000;
      img{
        width: 1rem;
        height: 1rem;
      }

    }
    .btn{
      color: #8c939d;
      i{
        margin-left: 4px;
        font-size: 0.5rem;
        padding: 0.3rem;
        border: 1px solid var(--colSubMain2);

      }
      i:hover{
        color: var(--colSubMain1);
        border:1px solid var(--colSubMain1);
      }
      span{
        margin-left: 30px;
      }
      span:hover{
        color: var(--colSubMain1);

      }
    }
  }
.images{
  margin-top: 1.5rem;
  img{
    max-height: 160px;

  }
}
.card-body{

  .col-3{
    padding-right:0;
  }
  .col-9{
    padding: 0;
  }
}
}
</style>