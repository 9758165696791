const axios = require('axios').default;
import { Message,Notification} from 'element-ui';
axios.defaults.validateStatus = function () {

    return true;
}
let urLList = [
    '',
    
]


const Api = {
    install() {
        // 注册网络请求
        this.prototype.axios = Api.netWork;
        this.prototype.deepcopy = Api.deepcopy;
        this.prototype.push = Api.push;
        this.prototype.toNewWindow = Api.toNewWindow
        this.prototype.pop = Api.pop;
        this.prototype.setCookie = Api.setCookie;
        this.prototype.getCookie = Api.getCookie;
        this.prototype.dataType = Api.dataType;
        this.prototype.getTimer = Api.getTimer;
        this.prototype.$message = Message ;// 注册ele全局提示
        this.prototype.$notify = Notification;


    },
    getCookie(key){
        let str = key + '=([^;]+)'
        let reg = new RegExp(str);
        let result = document.cookie.match(reg)
        return result && result[1]
    },
    setCookie(key, value, n = 0, path = '') {
        let str = key + "=" + escape(value);
        if (n > 0) {
            str +=  ";expires=" + (new Date().getTime() + n);
        }
        if (path !== '') {
            str += ';path=' + path
        }
        document.cookie = str
    },
    /** 
     * @param params {Object}
     * @param urlIndex {Number}
     * @param isImage {Boolean}
     * @param isAutomaticThrowError {Boolean} 是否自动抛出错误
     * @param postQuery {Boolean} 为post时可能为query方式传递数据
     * @returns {void}
     */
    netWork(params, urlIndex = 0, postQuery = false) {
        // let token = this.getCookie('token')


        axios.defaults.baseURL = urLList[urlIndex]

        let { method, data, success } = params;
        if (method === "get" || method === "delete" || postQuery) {
            Object.keys(data).forEach((key, i) => {
                if (i === 0) params.url += "?" + key + "=" + data[key];
                else params.url += "&" + key + "=" + data[key];
            });
        }
        Reflect.deleteProperty(params, "success");
        new Promise((resolve, reject) => {
            axios(params).then(res=>{

                if (res.data.msg !== '200') {
                    reject(res)
                } else resolve(res)
            }, errorInfo=>{
                errorInfo = this.deepcopy(errorInfo)
                if (errorInfo.data.msg=== 402){
                    this.$message({
                        type: 'warning',
                        message: '登录权限过期，请重新登录！',
                        onClose: ()=>{
                            this.push('/login')
                        }
                    })
                }
            })
        }).then(success).catch((errorInfo)=>{
          
            if (errorInfo.data.msg !== '200') {

                this.$message({
                    type: 'warning',
                    message:errorInfo.data.date  ,

                })

                if(errorInfo.data.msg === '402'){
                    this.push('/login')

                }
            }
        })
    },
    // 序列化
    deepcopy(obj) {
        return JSON.parse(JSON.stringify(obj));
    },
    // 路由跳转
    push(param) {
        this.$router.push(param);
    },
    /**
     * @param {String} url 跳转地址
     * @param {Object} param 传递的参数值
     * @param {String} baseUrl
     */
    toNewWindow(url, param, baseUrl = 'http://127.0.0.1:8080'){
        let _url = baseUrl + url + '?'
        Object.keys(param).forEach((key, i)=>{
            if (i>0) _url += '&'
            _url += '' + key + '=' + param[key]
        })
        window.open(_url, new Date().getTime().toString());
    },
    // 返回
    pop() {
        this.$router.go(-1)
    },
    /**
     * 判断数据类型 返回结果 --> null object function boolean ......
     * @param v
     * @returns {string}
     */
    dataType(v){
        let str = Object.prototype.toString.call(v)
        return str.substring(8, str.length-1).toLowerCase()
    },
    /**
     * @param data {boolean} 是否返回为日期格式
     * @param notHMS {boolean} 是否只要年月日
     * @param notYMD {boolean} 是否只要时分秒
     * @param sign {string}    年月日拼接符号
     * @param sign2 {string}    时分秒拼接符号
     * @returns {string}
     */
    // getTimer(data = false, notHMS = false, notYMD = false, sign = '-', sign2 = ':') {
    //   function double(val) {
    //     if (val < 10) val = '0' + val;
    //     return val;
    //   }
    //
    //   let nowTimer = new Date(),
    //     year = double(nowTimer.getFullYear()),
    //     month = double(nowTimer.getMonth() + 1),
    //     date = double(nowTimer.getDate()),
    //     hours = double(nowTimer.getHours()),
    //     minutes = double(nowTimer.getMinutes()),
    //     seconds = double(nowTimer.getSeconds());
    //
    //   let ymd = '',
    //     hms = '';
    //   if (data) {
    //     ymd = year + '年' + month + '月' + date + '日';
    //     hms = hours + '时' + minutes + '分' + seconds + '秒'
    //   } else {
    //     ymd = year + sign + month + sign + date;
    //     hms = hours + sign2 + minutes + sign2 + seconds
    //   }
    //
    //   if (notYMD) return hms;
    //   if (notHMS) return ymd;
    //
    //   return ymd + ' ' + hms;
    // },
}
const mixins = {
}

export { Api, mixins }
