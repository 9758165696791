import {
  Image,
  Input,
  InputNumber,
  Pagination,
  Tabs,
  TabPane,
  Checkbox,
  Carousel,
  CarouselItem,
  Steps,
  Step,
  Form,
  FormItem,
  Button,
  Rate,
  RadioGroup,
  Radio,
  Upload,
  Dialog,
  Menu,
  DatePicker,
  Select,
  Option,
  Progress,
  MenuItem,
  Table,
  TableColumn,
  Cascader,
  Result,
  Badge,


} from 'element-ui';


const installElementUI = function (vue) {
  vue.use(Image)
  vue.use(Cascader)
  vue.use(Table)
  vue.use(TableColumn)
  vue.use(MenuItem)
  vue.use(Select)
  vue.use(Option)
  vue.use(Input)
  vue.use(Menu)
  vue.use(Progress)
  vue.use(InputNumber)
  vue.use(Pagination)
  vue.use(Tabs)
  vue.use(TabPane)
  vue.use(Checkbox)
  vue.use(Carousel)
  vue.use(CarouselItem)
  vue.use(Steps)
  vue.use(Step)
  vue.use(Form)
  vue.use(FormItem)
  vue.use(Button)
  vue.use(Rate)
  vue.use(RadioGroup)
  vue.use(Radio)
  vue.use(Upload)
  vue.use(Dialog)
  vue.use(DatePicker)
  vue.use(Menu)
  vue.use(Result)
  vue.use(Badge)


}

export default installElementUI