import Vue from 'vue'
import Router from 'vue-router'


import components_lyh from './router_lyh'
import Index from '../page/index/index'

import error from '../page/404'

const merge = require('webpack-merge');

Vue.use(Router);

const router =  new Router(merge({
    // mode: 'history',

    routes: [
        // index トップページ
        { path: '/', redirect: '/index' },
        { path: '/index', component: Index },
        {path: '*', component: error, name: '404'}
    ]
},  components_lyh));

// 不需要验证登录的路由
let verifyRouterList = [
    '/index',
    '/login',
    '/classify',
    '/personal',
    '/particulars',
    '/register',
    '/announce',
    '/publicity',
    '/feedback',
    '/drawn',
    
]

router.beforeEach((to, from, next)=>{
    // true 不需要验证  false需要验证
    let result = verifyRouterList.some((item)=>{
        return item === to.path
    })
    if (!result && !document.cookie.match(new RegExp('token=([^;]+)'))) {
        next('/login')
    } else next()
})

export default router;
