<template>
  <div class="carousel">
    <el-carousel indicator-position="outside"  height="100%">
      <el-carousel-item v-for="(item,index) in banner" :key="index">
        <img :src="item.image" alt="" class="img-fluid">
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default {
  name: "banner",
  data(){
    return{
      banner:{},
    }
  },
  created() {

    this.axios({
      method: 'get',
      url: 'index/index/banner_img',
      data: {
        lang:'ja',
        lang_nub:2,
      },
      success: (res) => {
       
        this.banner=res.data.data
      
      }
    })
  }
}
</script>
<style lang="less" scoped>
.carousel{
  max-width: 1400px;

  min-height: 200px;
::v-deep {
  .el-carousel__container{
    min-height: 200px;
  }
  @media screen and (max-width: 699px) and (min-width: 520px), (min-width: 1151px) {
    .el-carousel__container{
      min-height: 300px;
    }
  }
  @media screen and (max-width: 1000px) and (min-width: 700px) {
    .el-carousel__container{
      min-height: 400px;
    }
  }
  @media screen and (min-width: 1001px) {
    .el-carousel__container{
      min-height: 500px;
    }
  }
}
.el-carousel__item img {
  width: 100%;
  height: 100%;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
}
</style>