<template>
<div class="order">
  <h2>订单详情</h2>
  <div class="row top">
    <div class="col-12 col-md-6">
      <span>
    订单编号：000000

      </span>
    </div>
    <div class="col-12 col-md-6">
      <i>未中奖</i>

    </div>
  </div>
  <div class="row shop">
    <div class="col-3 text-center">
      <img src="https://gw.alicdn.com/imgextra/i3/3901361754/O1CN01kX54nj1OpNPa1oo64_!!3901361754.jpg_Q75.jpg_.webp" alt="">
    </div>
    <div class="col-9 ">
          <h5>苹果999</h5>
          <h4>简介</h4>
          <H3>数量++++</H3>


    </div>
  </div>
  <div  class="serial">
    <h3>获得的随机编号</h3>
    <h4>99988</h4>


  </div>

</div>

</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.order{
  background-color: #fff;


  h2{
    color: #000000;
    font-size: 1.5rem;
    margin-top: 20px;
  }
  .top{
    width: 100%;
    max-width: 400px;
    padding: 10px;
    color: #000;
    i{
      color: #ffcd39;
    }
  }
  .shop{
    width: 80%;
    margin: auto;
    max-width: 1400px;

    .col-3{
      border: 1px solid var(--colSubMain2);
      img{
        margin: auto;
        width: 100%;
        max-width: 450px;
        min-width: 40px;

      }
    }
    .col-9{
      padding-left: 20%;
      border: 1px solid var(--colSubMain2);

    }
    h5{
      margin-top: 10%;
      color: #000000;

    }
    h3{
      font-size: 0.6rem;
      margin-top: 10px;
    }
    h4{
      font-size: 0.8rem;
     color: #000000;
    }
  }
  .serial{
    width: 80%;
    margin:20px auto ;
    max-width: 1400px;
  padding: 0;
    border: 1px solid var(--colSubMain2);
    h3{
      color: #000000;
      line-height: 2rem;
      padding-left: 2rem;
    }
   h4{
        width: 100%;
        border: 1px solid var(--colSubMain2);
     text-align: center;
     line-height: 1.5rem;

      }

  }

}
</style>