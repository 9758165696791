<template>
<div class="classify">
  <main>
    <div class="row">
      <div class="container">
        <ul>
          <li class="pointer" v-for="(item,index) in kind" :key="index" @click="handCation(item.id)">
            <div>
            <p>
              {{item.name}}
            </p>
          </div>
          </li>


        </ul>
      </div>

    </div>
    <div class="classifys row">
      <div class="col-3 pointer " @click="handChoice(1)">
        <h6 :class="[classifys===1?'active':'']">
          人気商品

        </h6>

        <h5 class="col-12" v-show="classifys===1"></h5>

      </div>
      <div class="col-3 pointer " @click="handChoice(2)">
        <h6
            :class="[classifys===2?'active':'']">
          最新の商品

        </h6>
        <h5 class="col-12"  v-show="classifys===2"></h5>

      </div>
      <div class="col-3 pointer " @click="handChoice(3)">
        <h6 :class="[classifys===3?'active':'']">
          満員間近

        </h6>
        <h5 class="col-12" v-show="classifys===3"></h5>

      </div>
      <div class="col-3 pointer ">
        <div class="sort" >


          <p class="h5" :class="[classifys===4?'active':'']" @click="handChoice(4)" >熱が高いです</p>
          <p class="h5" :class="[classifys===5?'active':'']" @click="handChoice(5)">熱が低いです</p>



        </div>
        <h5 class="col-12" v-show="classifys===4"></h5>
        <h5 class="col-12" v-show="classifys===5"></h5>


      </div>

    </div>
    <div class="hot row">
      <div class="col-6 col-md-4 col-lg-3" v-for="(item,index) in shopList" :Key="index">
        <card :details="item"></card>

      </div>




    </div>
  </main>

</div>
</template>

<script>
import card from "@/components/card.vue";


export default {
  components:{card},

      data(){
        return{

          classifys:1,
          kind:"",
          en:'ja',
          choice:"",
          cation:6,
          shopList:[],
        }
      },
  created() {
    this.axios({
      method: 'get',
      url: 'index/index/kind_class?lang=ja',
      data: {},
      success: (res) => {

        this.kind=res.data.data

      }
    })
    this.handKind()
  },
  methods:{
        handCation(id){
          this.cation=id
          this.handKind()
        },
        handChoice(q){
          this.classifys=q
          this.handKind()

        },
        handKind(){
          let i=''
          if( this.classifys===4){
            i='sive'
          }else if(this.classifys===5){
            i='cheap'
          }else{
            i=this.classifys
          }
          let url='index/index/product_recommend'+'?lang'+'='+this.en+'&choice'+'='+i+'&cation'+'='+this.cation
          this.axios({
            method: 'get',
            url,
            data: {},
            success: (res) => {
            
              this.shopList=res.data.date
           


            }
          })
        },

  }
}
</script>

<style lang="less" scoped>
.classify{
  background-color: #fff;
  main{
    width: 100%;
    max-width: 1400px;
    margin:auto;
    .container{
      margin: 50px 0 20px;
    }
    .container > ul {
      width: 95%;
      display: flex;
      flex-wrap: wrap;
      margin:auto;

      border-radius: 0.8rem;
    }
    .container > ul li {
      width: 33.33%;
      display: flex;
      justify-content: center;

    }
    @media screen and (max-width: 320px) {
      .container ul > li {
        width: 33%;
      }
    }
    @media screen and (min-width: 768px) {
      .container ul > li {
        width: 16%;
      }
    }
    .container > ul li > div {
      width: 100%;
      margin: 0.5rem;
      text-align: center;
      line-height: 2rem;
      background-color: #ff4c42;
      color: #ffffff;
      border-radius: 20px;
    }
    .screen{
      width: 95%;
      margin: auto;
       display: flex;
      flex-wrap:wrap ;

      justify-content: space-between;

      p{


        width: 15%;
        min-width: 120px;

      }
    }
    .classifys{

      margin: auto;
      background-color: var(--colSubMain2);
      width: 95%;

      max-width: 1400px;
      height: 3rem;
      .sort{

          .h5{
            font-size: 0.4rem;
            color: #000000;
            line-height: 1.5rem;
            margin: 0;
        }
      }
      .active{
        color: var(--colSubMain1) !important;

      }
      .col-3{
        position: relative;
        text-align: center;
        padding: 0 !important;


        h6{
          line-height: 3rem;
          color: #000;
        }
        .h6{
          line-height: 3rem;
          color: #000;
          margin: 0;

        }
        h5{
          position: absolute;
          width: 100%;
          height: 0.2rem;
          background-color: var(--colSubMain1);
          margin: 0;

        }

      }

    }
    .hot{
      width:98%;
      max-width: 1400px;
      margin: auto;
    }
  }

}
</style>