<template>
  <div>
    <div class="partisculars">

    <div class="row ">
      <div class="col-12 col-sm-12 col-md-6 mt30">
      <el-carousel trigger="click" >
        <el-carousel-item v-for="(item,index) in part.images" :key="index">
          <img :src="item" alt="">
        </el-carousel-item>
      </el-carousel>
      </div>

      <div class="col-12 col-sm-12 col-md-6">
        <p class="h2">{{part.title}}</p>
        <p class="h1">{{part.needed}}</p>
        <p class="h6">参与人数{{part.part_son}}</p>
        <el-progress :text-inside="true" :stroke-width="16" :percentage="perce" :color="customColor"></el-progress>
        <div class=" shop">

            <button class="btn" type="button" @click="handPush()">
              参与
            </button>


            <img src="../../../public/img/cart.png" alt="" @click="handShopping()"></div>

      </div>
  </div>
      <div class="record">
          <div class="records" v-for="(item,index) in pate" :key="index">
            <img :src="item.image" alt="">
            <div class="name">
              <p class="h5">{{item.user}}</p>
              <p class="h6"><span>参与 <i>{{item.pate}}</i> 次</span> <span>{{item.time}}</span> </p>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {

  data(){
    return{
      customColor:'#ffc107',
      id:'',
      part:[],
      perce:0,
      pate:[],


    }
  },
  created() {
      this.id=this.$route.query.id

    let url='index/Details/details_tent'+'?lang'+'='+'ja'+'&id'+'='+this.id
    this.axios({
      method: 'get',
      url,
      data: {},
      success: (res) => {
       
        this.part=res.data.date
        this.pate=res.data.pate
        
        let i=(this.part.part_son/this.part.needed)*100
        this.perce=Math.trunc(i)

      }
    })

  },
  methods:{
    handPush(){
      this.$router.push({
        path: '/clearing',
        query: {
          id: this.id
        }
      })

    },
    handShopping(){
      let token=this.getCookie('token')

      this.axios({
        method: 'post',

        url: '/index/Details/shopping',
        data: {
          token,
          pid:this.part.id,
          number:1,
          lang_nub:2,
        },
        success: (res)=>{
         
          if(res.data.msg!==200){
            this.$message({
              type: 'warning',
              message: res.data.date,

            })
          }else{
            this.$message({
              type: 'success',
              message: res.data.date,

            })
          }
        }
      } )
    }
  }

}
</script>

<style lang="less" scoped>
.partisculars{
  background-color: #fff;
    color: #000000;
}
.mt30{
  margin-top: 30px;
}
.row{
  max-width: 1400px;
  margin: auto;
  .h1{
    color: var(--colSubMain1);
  }
  .h2{
    margin-top: 40px;
  }
  .h6{
    margin-top: 10px;
    color: var(--colSubMain2);
  }
  .el-progress{
    margin-top: 10px;
    width: 80%;
  }
  .shop{
    width: 60%;
    display: flex;
    margin: auto;
    padding-top: 40px;
    .btn{

      min-width: 200px;
      background-color: #fff;
      color:#ffffff;
      max-width: 200px;
      border-radius: 1.5rem;
      background-color: var(--colSubMain1);
      border:1px solid var(--colSubMain1);
      font-weight: bold;
      font-size: 1rem;


    }
    img{
      margin-left: 2rem;
      height: 2rem;
    }
  }


}
.record{
  padding: 20px;
    max-width: 1400px;
  margin: auto;
  .records
  {
    display: flex;
    padding: 10px 0;
    margin-top: 20px;
    border-bottom: 1px solid var(--colSubMain2);
    img{
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
    }
    .name{
      margin-left: 20px;

      .h6{
        color: var(--colSubMain2);
      }
    }
  }

}

.el-carousel__item img{
  width: 100%;
  height: 100%;
}

::v-deep {
  .el-carousel__container{
    min-height: 350px;
  }
  @media screen and (max-width: 699px) and (min-width: 520px), (min-width: 1151px) {
    .el-carousel__container{
      min-height: 500px;
    }
  }
  @media screen and (max-width: 1000px) and (min-width: 700px) {
    .el-carousel__container{
      min-height: 500px;
    }
  }
  @media screen and (min-width: 1001px) {
    .el-carousel__container{
      min-height: 600px;
    }
  }
}

</style>