<template>
  <div class="announce">
    <p class="h1">すぐに賞を出す</p>

    <div class="hot row">



      <div class="col-6 col-md-4 col-lg-3" v-for="(item,index) in  this.publicityList" :key="index">
        <card :details="item" :startTime="item.time" :endTime="item.tiem"></card>
<!--        <div class="card " @click="handPush(item.pid,item.bh)">-->
<!--          <img :src="item.image" class="card-img-top" alt="...">-->
<!--          <div class="card-body">-->
<!--            <p class="h5 col333 ov-h">-->
<!--              {{item.title}}-->
<!--            </p>-->
<!--            <p class="h6 col333">-->
<!--              <span>商品品番です：</span>  <i>{{item.bh}}</i>-->
<!--            </p>-->

<!--  <p class="h3 colff0">-->
<!--    <i class=" el-icon-time"></i><span>すぐに賞を出す</span>-->
<!--      </p>-->
<!--            <p class="h2">-->
<!--              {{item.time}}-->
<!--            </p>-->

<!--          </div>-->
<!--        </div>-->

      </div>




    </div>

  </div>
</template>

<script>
import card from '@/page/drawn/components/card.vue'

export default {
  components:{card},
  data(){
    return{
      page:1,
      publicityList:[],
    }
  },
  created() {
    this.axios({

      method: 'post',
      url:'index/drawprize/wait_pro',
      data: {

        lang:'ja',
        lang_nub:2,
      },
      success: (res) => {
        this.publicityList=res.data.date
       let arrList=this.publicityList
        console.log(res.data.date)
      arrList.forEach((item)=>{

        let yy = new Date().getFullYear();
        let mm = new Date().getMonth()+1;
        let dd = new Date().getDate();
        let hh = new Date().getHours();
        let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
        let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds()
        item.time =yy+'-'+mm+'-'+dd+' '+hh+':'+mf+':'+ss;

      })


      }
    })
  },
  methods:{
    handPush(id,bh){

      this.$router.push({
        path: '/publicity',
        query: {
          id:id,
          bh:bh,
        }
      })
    },

  }
}
</script>

<style lang="less" scoped>
.announce{
  background-color: #fff;
  .h1{
    color: #333333;
    margin: 30px 0;

  }
  .hot{

    max-width: 1200px;
  }
  .card{
    margin-top: 30px;
  }
  .col-6{
    padding: 0 5px;
  }
  .colff0{
    color: var(--colSubMain1);
  }
  .ov-h{
    height: 3rem;
    display:-webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2;
    overflow:hidden;
    text-overflow:ellipsis;
  }

}
</style>