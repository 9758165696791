<template>
<div class="compile">
  <div class="main">
    <h2 class="text-center">個人情報の編集</h2>

    <div class="upload">
      <el-upload
          class="avatar-uploader text-center"
          action="/api/index/user/attachment"
          :data="dataList"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload">
        <img v-if="imageUrl" :src="imageUrl" class="avatar">
        <i v-else class="el-icon-plus avatar-uploader-icon" style="border: 1px solid var(--colSubMain2)"></i>
      </el-upload>

    </div>
    <main >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="ユーザー" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="email" prop="email">
          <el-input  v-model="ruleForm.email" ></el-input>
        </el-form-item>
        <div class="btn">
          <button @click="submitForm('ruleForm')" type="button">提出</button>

        </div>
      </el-form>
    </main>
  </div>
</div>
</template>

<script>
export default {
  name: "container",
  data() {
    return {
      imageUrl: '',
      profile:'',
      ruleForm: {
        name: '963852',
        email:'963852741',

      },
      dataList:{
        token:this.getCookie('token')},
      rules: {
        name: [
          { required: true, message: '请输入ユーザー或邮箱', trigger: 'blur' },

        ],
        email: [
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ]


      }
    };


  },
  created() {
    this.axios({
      method: 'post',

      url: 'index/user/profile',
      data: {
        token:this.getCookie('token'),
      },
      success: (res)=>{
        this.profile=res.data.data
        this.imageUrl=this.profile.image
        this.ruleForm.name=this.profile.user
        this.ruleForm.email=this.profile.emli

        console.log(this.profile)

      }
    })
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {

          this.axios({
            method: 'post',

            url: 'index/user/update',
            data: {
              token:this.getCookie('token'),
              user:this.ruleForm.name,
              emli:this.ruleForm.email,


            },
            success: (res)=>{
              console.log(res)
              if(res.data.msg==='200'){
                this.$router.push('/personal')

              }
            }
          } )
        } else {

          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);

    },
    beforeAvatarUpload(file) {
      console.log(file)
      let isImg =false
     if(file.type === 'image/jpeg'){
       isImg=true
     } else if(file.type=== 'image/png'){
       isImg=true

     }else{
        isImg =false

     }

      const isLt2M = file.size / 1024 / 1024 /1024 < 3;

      if (!isImg) {
        this.$message.error('请上传图片文件 !');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isImg && isLt2M;
    }
  }
}
</script>

<style lang="less" scoped>
.compile{
  height: 100%;
  background-color: #fff;
  padding-bottom:15rem ;
  .main{
    width: 350px;

  }
  h2{
    margin-top: 2rem;
    color: #000;
    font-size: 2rem;
  }
  .upload{
    margin:2rem auto;
  }
  .main{
    max-width: 360px;
    margin: auto;
    text-align: center;
    padding-top: 30px;
    ::v-deep .el-form-item {
      width: 400px;
      margin:20px auto;
      display: flex;
      label{
        width: 80px !important;
        margin-left: 20px;
      }
      .el-form-item__content{
        margin-left: 5px !important;
        width: 220px;
      }
      .el-input{
        width: 240px;
      }
    }
    button{
      width: 60px;
      height: 40px;
      background-color: var(--colSubMain1);
      color: #ffffff;
      font-size: 1rem;
      border-radius: 5px;

    }
    i{
      margin-left: 20px;

    }
    i:hover{
      color: var(--colSubMain1);

    }
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>