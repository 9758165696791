<template>
<div class="skype">
  <p class="h2 col333">金货チャージ</p>

  <div class="money">
  <p class="h4"> チャージする金貨を選びます</p>
    <div class="row">
      <p class="h4 col-3 text-center" v-for="(item,index) in moneyList" :key="index" @click="handSet(item,index)"><i :class="[active===index?'active':'']">{{item.gold}}</i></p>



    </div>
    <div class="payment col333">
      需要的金额：<span  class="colffc h2">{{money}}</span>
    </div>

  </div>
  <div class="text-center">
    <button type="button" class="btn btn-primary btn-lg" @click="handPay()">チャージ</button>


  </div>
</div>
</template>

<script>
export default {
  data(){
    return{
      moneyList:[],
      money:0,
      active:-1,
      id:0,
    }
  },
  created() {
    this.axios({
      method: 'post',
      url:"index/paypal/pay_money",
      data: {
        lang:'ja'
      },
      success: (res) => {
       
      this.moneyList=res.data.data
   


      }
    })
  },
  methods:{
    handSet(item,index){
          this.money=item.money
        this.active=index
        this.id=item.id
    },
    handPay(){
      if(this.id===0){
        this.$message({
          type: 'warning',
          message:'请选择需要チャージ的金额'  ,

        })
      }else{
        this.axios({
          method: 'post',
          url:"index/paypal/pay",
          data: {
            token:this.getCookie('token'),
            lang:'en',
            id:this.id

          },
          success: (res) => {
       
              if(res.data.msg==='200'){
                window.open(res.data.url, "_blank");
                this.push('/inquire')

              }
          }
        })
      }

            }
  }
}
</script>

<style lang="less" scoped>
.skype{
  background-color: #fff;
  padding-bottom: 10rem;
  .h2{

    margin-top: 4rem;

  }
  .money{
    width: 80%;
    max-width: 1400px;
    margin: auto;
    h1{
      font-size: 1.5rem;
      margin-bottom: 2rem;

    }
    .h4{
      margin: 30px 0;
    }
    i{
      border: 1px solid var(--colSubMain2);
      border-radius: 5px;
      padding: 0 5px;


    }
    .active{
      border: 1px solid #0d6efd;
      color: #0d6efd;
    }
  }

  .btn{
    width: 10rem;
    padding: 0.25rem 0;
    margin-top: 2rem;
  }
  .payment{

  }
}
</style>