<template>
<div class="information">

  <div class="read" v-if="read.length>0">
    <div class="btn">
      <el-button type="primary" icon="el-icon-message" @click="handEad()">すべて既読</el-button>
    </div>
  <div class="title" v-for="(item,index) in read" :key="index">
    <p class=" col333"> <span class="h4">{{item.title}}</span></p>
      <div class="content" v-html="item.content"></div>
    <p class="h6" > <i class="col119 pointer" @click="handPush(item.id,item.bh)"> 詳細を調べます</i> <span>{{item.time}}</span> </p>
  </div>
</div>
  <p class="h2 col999" v-if="read.length===0">しばらく消息がない</p>

</div>
</template>

<script>
export default {
  data(){
    return{
      read:[],
    }
  },created() {
    this.axios({
      method: 'post',
      url:'index/Drawprize/not_read',
      data: {
        token:this.getCookie('token'),
        lang:'ja'
      },
      success: (res) => {
        console.log(res.data)
          this.read=res.data.row
      }
    })
  },methods:{
    handPush(id,bh){

      this.$router.push({
        path: '/publicity',
        query: {
          id:id,
          bh:bh,
        }
      })
    },
    handEad(){
      this.axios({
        method: 'post',
        url:'index/Drawprize/ead',
        data: {
          token:this.getCookie('token'),
          lang:'ja'
        },
        success: (res) => {

         if(res.data.msg===200){
           this.read=[]
         }

        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .information{
    background-color: #fff;
    width: 100%;
    height: 100%;
    .read{
      width: 80%;
      margin: auto;
      max-width: 1400px;
    }
    .h2{
      height: 100%;
      margin:20% 0;
    }
    .title{
      padding: 20px ;
      border-bottom: 1px solid #f6f6f6;
      .content{
      color: #000000;
        margin-top: 20px;
      }
      .h6{
        margin-top: 20px;
        span{
          margin-left: 50px;
        }
      }
    }
  }
</style>