<template>
<div class="addressing">
   <h2>地址编辑</h2>
  <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">

    <div class=" row">
      <div class="row address col-12 col-md-6 " >

      <el-form-item label="名" prop="name">
      <el-input v-model="ruleForm.name"></el-input>
    </el-form-item>
        </div>
      <div class="row address col-12 col-md-6 " >

      <el-form-item label="姓" prop="nature">
        <el-input v-model="ruleForm.nature"></el-input>
      </el-form-item>
      </div>
    </div>
        <div class="col-12 row">
          <el-form-item label="目的地" prop="region">
            <el-select v-model="ruleForm.region" placeholder="请选择活动区域">
              <el-option
                  label="America"
                  value="America">
                <span style="float: left">America</span>
                <span style="float: right; color: #8492a6; font-size: 13px"></span>
              </el-option>
              <el-option
                  label="Japan"
                  value="にっぽん">
                <span style="float: left">Japan</span>
              </el-option>
            </el-select>
          </el-form-item>
        </div>
  </el-form>
  <div class="row"    v-if="ruleForm.region==='America'">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">

      <div class=" row address col-12 ">
        <el-form-item label="state" prop="province">
        <el-input v-model="ruleForm.province"></el-input>
      </el-form-item>
      </div>
      <div class=" row address col-12  " >
        <el-form-item label="county" prop="city">
          <el-input v-model="ruleForm.city"></el-input>
        </el-form-item>
      </div>
      <div class=" row address col-12">
        <el-form-item label="city" prop="district">
          <el-input v-model="ruleForm.district"></el-input>
        </el-form-item>
      </div>

    <div class=" row address col-12">
      <el-form-item label="地址" prop="detailed">
        <el-input v-model="ruleForm.detailed"></el-input>
      </el-form-item>
    </div>
    <div class=" row address col-12">
      <el-form-item label="邮编" prop="detailed">
        <el-input v-model="ruleForm.postcode"></el-input>
      </el-form-item>
    </div>
    <div class=" row address col-12">
      <el-form-item label="电话" prop="phone">
        <el-input v-model="ruleForm.phone"></el-input>
      </el-form-item>
        </div>

    </el-form>

  </div>
  <div class="row japan" v-if="ruleForm.region==='にっぽん'">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">

    <div class="fx row postcode">
      <div class="col-6 address row">
      <el-form-item label="邮编" prop="postcode">
        <el-input v-model="ruleForm.postcode1"  maxlength="3"></el-input>
      </el-form-item>
      </div>

      <div class="col-5 fx">
        <i>一</i>

      <el-form-item label="" prop="postcode">
        <el-input v-model="ruleForm.postcode2" maxlength="4"></el-input>
      </el-form-item>
      </div>
      <div class="col-1">
        <i class="el-icon-search" @click="handPostCode()"></i>
      </div>
    </div>
    <div class=" row address col-12 ">
      <el-form-item label="都道府县" prop="province">
        <el-input v-model="ruleForm.province"  :disabled="true"></el-input>
      </el-form-item>
    </div>
    <div class=" row address col-12 ">
      <el-form-item label="市区町村 " prop="city">
        <el-input v-model="ruleForm.city"  :disabled="true"></el-input>
      </el-form-item>
    </div>
    <div class=" row address col-12 ">
      <el-form-item label="町域名" prop="district">
        <el-input v-model="ruleForm.district"  :disabled="true"></el-input>
      </el-form-item>
    </div>
    <div class=" row address col-12 ">
      <el-form-item label="门牌号" prop="detailed">
        <el-input v-model="ruleForm.detailed" ></el-input>
      </el-form-item>
    </div>

    <div class=" row address col-12 ">
      <el-form-item label="电话" prop="phone">
        <el-input v-model="ruleForm.phone" ></el-input>
      </el-form-item>
    </div>
    </el-form>

  </div>
    <div class="row">
      <div class="col-1"></div>
      <div class="checkbox col-6">
        <el-checkbox v-model="checked1">设为首选收货地址</el-checkbox>
        <el-checkbox v-model="checked2">设为首选账单地址</el-checkbox>
      </div>

    </div>
  <div class="row">

    <button class="btn" type="button" @click="submitForm('ruleForm')">
      提出
    </button>
  </div>

</div>
</template>

<script>
export default {
  data() {
    return {
      checked1:false,
      checked2:false,
      ruleForm: {
        name: '',//名
        nature:'',//姓
        region: '',//国别
        phone:'',//电话
        province:'',//省
        city:"",//市
        district:'',//区

        detailed:'',//详细地址
        postcode:'',//美国邮编
        postcode1:'197',//日本邮编前3位
        postcode2:'0804'//日本邮编后四位
      },
      rules: {
        name: [
          { required: true, message: '请输入姓', trigger: 'blur' },
        ],
        nature:[
          { required: true, message: '请输入名', trigger: 'blur' },
        ],
        region: [
          { required: true, message: '请选择送达地址', trigger: 'change' }
        ],
        province: [
          { required: true, message: '请选择送达地址', trigger: 'blur' }
        ],
        city: [
          { required: true, message: '请选择送达地址', trigger: 'blur' }
        ],
        district: [
          { required: true, message: '请选择送达地址', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入电话号码', trigger: 'blur' }
        ],
        detailed:[
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ],
        postcode1:[
          { required: true, message: '请输入完整邮编', trigger: 'blur' },
          { min: 3, max: 3, message: '请输入三位数', trigger: 'blur' }
        ],
       postcode2:[
       { required: true, message: '请输入完整邮编', trigger: 'blur' },
       { min: 4, max: 4, message: '请输入四位数', trigger: 'blur' }
     ]
      },
    }
  },created() {
    
  },

  methods:{
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
        

          let i=this.$route.query.id
          let postal='';
          let default1 = (this.checked1?'1':'0')
          let bill= (this.checked2?'1':'')
          let z='';

          if(i===1){
            z=1
          }
          if(this.ruleForm.region==='America'){
                postal=this.ruleForm.postcode
          }else{
            postal=this.ruleForm.postcode1+'-'+this.ruleForm.postcode2
          }
          this.axios({
            method: 'post',
            url:'index/user/add_res',
            data: {
              token:this.getCookie('token'),
              id:z,
              end:this.ruleForm.region,
              postal,
              name:this.ruleForm.name,
              nature:this.ruleForm.nature,
              province:this.ruleForm.province,
              city:this.ruleForm.city,
              district:this.ruleForm.district,
              phone:this.ruleForm.phone,
              detailed:this.ruleForm.detailed,
              default:default1 ,
              bill,
            },
            success: (res) => {
              if(res.data.msg==='200'){
                this.$router.push({
                  path: '/address',
              
                })
              }
            }
          })
        } 
      });
    },
    handPostCode(){
      this.axios({
        method: 'post',
        url:"index/user/add_ving",
        data: {
          yb:this.ruleForm.postcode1+'-'+this.ruleForm.postcode2
        },
        success: (res) => {
          let list=res.data.date
          this.ruleForm.city=list.city
          this.ruleForm.district=list.district
          this.ruleForm.province=list.province

        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.addressing{
  background-color: #fff;
  padding-top: 2rem;
  .row{
    max-width: 800px;

    h3{
      width: 10%;
      min-width: 100px;
      text-align: right;
      color: #000000;
      line-height: 2.5rem;

    }
  }
  h2{
    font-size: 2rem;
    color: #000000;
    margin-bottom: 40px;
  }
  .fx{
    display: flex;
  }
  .mt20{
    margin-top: 20px;
  }
  .ma-auto{
    margin: auto;
  }

  .btn{
    width: 80%;
    max-width: 200px;
    margin:20px auto;
    border: 1px solid var(--colSubMain1);
    background-color: var(--colSubMain1);
    color: #fff;
    font-size: 1.2rem;
  }
}
::v-deep .el-form-item {


  display: flex;
  padding:0 ;
  label{
    width: 101px !important;
    margin-left: 20px;
  }
  .el-form-item__content{
    margin: 0 !important;
    width: 100%;
    .el-select{
      width: 100%;
    }
  }

}
.address{

  ::v-deep .el-form-item {
    width: 100%;
    padding: 0;
    label{
      width: 101px !important;
      margin-left: 20px;
    }
    .el-form-item__content{
      margin-left: 5px !important;
      width: 100%;
      .el-input{
        width: 100%;
      }
    }
  }
.checkbox{
  width: 80%;
  margin: auto;
  padding-right: 50px;
}

}
.japan{
  .col-6{
    padding-right: 0;
    ::v-deep .el-form-item {

      label{
        width: 120px !important;

      }

    }
  }
  .col-5{
    padding-left: 0;
  }
  .col-1{
    padding: 0;
    i{
      border:1px solid var(--colSubMain2);
      margin: 0;
      padding: 0 50%;
      border-radius: 5px;
      font-size: 0.5rem;
    }
  }
  i{
    line-height:40px ;
    font-size: 1rem;
    color: #000000;
    margin: 0 10px;
  }
}
</style>