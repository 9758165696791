<template>
  <div>
    <div class="index fx">
      <div class="index-left">

      </div>
      <div class="index-right">
        <P class="col333">404</P>
        <p class="col333">UH OH~页面丢失</p>
        <p class="col666">您所寻找的页面不存在，你可以点击下面按钮，返回主页。</p>
        <a class="pointer-col" href="/index">返回トップページ</a>
        <p class="f16">{{ h }}秒后自动为您，返回トップページ!</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      h: 5

    }
  },
  methods: {
    time() {
      let a = 5;
      let timer = setInterval(function () {
        this.h = a--;
        if (this.h == 0) {
          clearInterval(timer)
          window.location.href = "/index"
        }
      }, 1000)
    },
  },
  created() {
    this.time();
    this.$emit('update:show', false)
  }
}

</script>

<style lang="less" scoped>
.index {
  align-items: center;
  width: 100%;
  .index-left {
    width: 600px;
    height: 600px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .index-right {
    width: auto;
    height: auto;
    float: left;

    p:nth-of-type(1) {
      font-size: 60px;
      margin-bottom: 30px;
    }

    p:nth-of-type(2) {
      font-size: 40px;
      margin-bottom: 20px;
    }

    p:nth-of-type(3) {
      font-size: 30px;
      margin-bottom: 30px;
    }

    a {
      width: 143px;
      height: 40px;
      background-color: var(--colMain);
      border-radius: 10px;
      font-size: 20px;
      text-align: center;
      line-height: 40px;
      color: var(--colFff);
      margin-bottom: 10px;
      display: block;
    }

    p:nth-of-type(4) {
      color: #F56629;
    }
  }
}
</style>