<template>
<div class="announce">
  <p class="h1">当選ランキング</p>

  <div class="hot row">



    <div class="col-6 col-md-4 col-lg-3" v-for="(item,index) in  this.publicityList" :key="index">
      <div class="card " @click="handPush(item.pid,item.bh)">
        <img :src="item.image" class="card-img-top" alt="...">
        <div class="card-body">
          <p class="h5 col333 ov-h">
            {{item.title}}
          </p>
          <p class="h6 col333">
          <span>商品品番です：</span>  <i>{{item.bh}}</i>
          </p>
          <p class="h6 col333">
            <span>受賞アカウントです：</span>
            <i class="colffc">{{item.user}}</i>
          </p>
          <p class="h6 col333">
           <span>
             参加者数：
           </span> <i>{{item.gold}}</i>
          </p>
          <p class="h6 col333">
            <span>
                ラッキーナンバーです：
            </span>
          <i class="colff0">{{item.cjbh}}</i>
          </p>
          <p class="h6 ">


            <i>{{item.tiem}}</i>
          </p>
        </div>
      </div>

    </div>

    <div class="page">
      <el-pagination
          background
          hide-on-single-page
          layout="prev, pager, next"
          :total="total"
          :page-size="20">
      </el-pagination></div>
</div>



</div>
</template>

<script>
export default {
    data(){
      return{
      page:1,
        total:0,
        publicityList:[],
      }
    },
  created() {
    this.axios({
      method: 'post',
      url:'index/drawprize/history',
      data: {
        page:this.page,
        limit:20,
        lang:'ja',
        lang_nub:0,
      },
      success: (res) => {
        this.publicityList=res.data.data
          this.total=res.data.fenye.number

        console.log(res)
      }
    })
  },
  methods:{
      handPush(id,bh){
        
      this.$router.push({
          path: '/publicity',
          query: {
            id:id,
            bh:bh,
          }
        })
      }
  }
}
</script>

<style lang="less" scoped>
.announce{
  background-color: #fff;

  .h1{
    color: #333333;
    margin: 30px 0;

  }
  .hot{

    max-width: 1400px;
    margin: auto;
    .page{
      margin-top: 20px;
    }
  }
  .card{
    margin-top: 30px;
  }
  .col-6{
    padding: 0 5px;
  }
  .colff0{
    color: var(--colSubMain1);
  }
  .ov-h{
      height: 3rem;
    display:-webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2;
    overflow:hidden;
    text-overflow:ellipsis;
  }

}
</style>