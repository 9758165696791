/** Vuex全局状态管理 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    isLogin: false, // 是否登录
    userInfo: {},
    messageSync: false,  // 点击头部消息列表时，控制消息界面消息的状态的同步
  },
  // computed property
  getters: {
    /**
     * 获取地址
     */
  
  },
  // commit method to update status
  mutations: {
    updateIsLogin(state, v) {
      state.isLogin = v
    },
    updateUserInfo(state, v){
      state.userInfo = v
    },
    changeMessageSync(state, newValue){
      state.messageSync = newValue;
  },
  },
  // commit to mutation    can use async
  action: {}
})

export default store
