<template>
<div class="cart">
  <h2>ショッピングカート</h2>
  <div class="clearing row  " v-if="false">

    <div class="col-1" ><el-checkbox v-model="checked"></el-checkbox></div>
    <div class="col-2"> <i class="h6">削除</i></div>
    <div class="col-5 "><i class="h6">合计：</i><i class="h5">9999</i></div>
    <div class="col-4">
      <button>结算</button>
    </div>
      <div></div>
  </div>
  <main>

  <div class="row shop " v-for="(item,index) in cartList" :key="index">
<!--    <div class="col-1 max-h100 col-sm-1"><el-checkbox v-model="checked"></el-checkbox></div>-->
    <div class="col-3 max-h100 ">
      <img :src="item.image" alt="" class="col-4">
    </div>
    <div class="col-1"></div>
    <div class="col-8">
      <div class="name ">
                <h5 class="col333">{{item.title}}</h5>
                <p class="h6"> 单价1金货 </p>

                <h6>总需人次：{{item.needed}}</h6>

                <p class="h6"> 已参加者数：{{item.part_son}} </p>
              </div>
      <div class="numberS">
<!--        <el-input-number size="mini" v-model="num4"></el-input-number>-->

        <button class="buy" type="button" @click="handPush(item.id)">購入する </button>
        <button class="del" type="button" @click="handDel(item.id,index)">削除 </button>

      </div>
      <div class="btnS">
<!--    <ul>-->
<!--      <li>10</li>-->
<!--      <li>20</li>-->
<!--      <li>30</li>-->
<!--      <li>50</li>-->
<!--      <li>包尾 </li>-->
<!--    </ul>-->
      </div>
    </div>
<!--    <div class="col-8 row col-sm-9">-->
<!--      <div class="name col-8 col-sm-4">-->
<!--        <h5 class="col333">{{item.title}}</h5>-->
<!--        <h6>{{item.needed}}</h6>-->
<!--      </div>-->
<!--      <div class="col-12 row col-sm-8">-->
<!--        <div class="col-7 max-h100">-->
<!--          <el-input-number size="mini" v-model="num4"></el-input-number> </div>-->
<!--        <div class="col-3"></div>-->
<!--        <div class="col-2 max-h100">-->
<!--          <button>删</button></div>-->
<!--      </div>-->
<!--    </div>-->
    </div>

  </main>
</div>
</template>

<script>
export default {
data(){
  return{
    num4:'',
    checked:false,
    cartList:[]

  }
},created() {
    let token=this.getCookie('token')

    this.axios({
      method: 'post',

      url: '/index/user/shop_cart',
      data: {
        token,

        lang:'ja',
        lang_nub:2,

      },
      success: (res)=>{
        console.log(res)
          if(res.data.error==='0'){
            this.cartList=res.data.date

          }
      }
    } )
  },
  methods:{
    handPush(id){
      this.$router.push({
        path: '/clearing',
        query: {
          id
        }
      })

    },
    handDel(id,index){
      let token=this.getCookie('token')

      this.axios({
        method: 'post',

        url: 'index/Details/delshop',
        data: {
          token,
            pid:id,
          lang_nub:0,

        },
        success: (res)=>{
          if(res.data.msg==='200'){
        
             this.cartList.splice(index,1)
            this.$message({
              type: 'warning',
              message: res.data.date,

            })
          }

        }
      } )
    }
  }

}
</script>

<style lang="less" scoped>
.cart{
  background-color: #fff;
  h2{
    font-size: 1.5rem;
    margin-top: 60px;
    color: #000000;
    margin-bottom: 30px;

  }
  .max-h100{
    max-height: 100px;


  }
main{
  margin:auto;
  max-width: 1400px;
}
  .shop{
    margin: 20px 0 ;
    border-bottom: 1px solid var(--colSubMain2);
    padding:5px 20px  50px;
    .col-1{
      padding: 0;
    }
    .col-2{
      padding: 0;
    }
    .col-8{
      padding: 0;
    }
    ::v-deep .el-input-number{

      max-width: 120px;
    }
  }
  .clearing{
    width: 100%;
    padding:30px 5%  20px;
    max-width: 1400px;
    line-height: 40px;
    border-top:1px solid var(--colSubMain2) ;

    border-bottom:1px solid var(--colSubMain2) ;
    .col-5{
      text-align: right;
      .h5{
        color: var(--colSubMain1);

      }



    }
    button{
      width: 100%;
      max-width: 120px;
      line-height: 40px;
      border-radius: 20px;
      background-color: var(--colSubMain1);
      color: #ffffff;
    }

  }
  .col-3{

    img{
      width: 100%;
      max-width: 100px;
      min-width: 60px;
    }
  }
.name{
  h5{
    display:-webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2;
    overflow:hidden;
    text-overflow:ellipsis;
  }
  h6{
    margin-top: 20px;
  }
  .h6{
    margin-top: 10px;
    color: #ffcd39;
  }
}
  .numberS{
    margin-top: 20px;
  }
  .buy{
    width: 60px;
    margin-left: 30px;
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: 0.8rem;
    background-color:var(--colSubMain1);
    color: #ffffff;
    border-radius: 20px;
  }
  .del{
    width: 60px;
    margin-left: 30px;
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: 0.8rem;
    background-color:var(--col999);
    color: #ffffff;
    border-radius: 20px;
  }

}
</style>