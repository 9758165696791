 <template>
  <section class="login">
    <div class="visible-xs"></div>
  <p class="h2">登录</p>
    <div class="main">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="ユーザー" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="pass">
          <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
        </el-form-item>
          <div class="btn">
            <button @click="submitForm('ruleForm')" type="button">提出</button>
            <router-link to="/register">
              <i class="col666 pointer-col">注册</i>

            </router-link>
          </div>
      </el-form>
    </div>
    <div class="visible-xs"></div>

  </section>

</template>
<script>  export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    return {
      ruleForm: {
        name: 'qweasd789',
        pass:'12345678',

      },
      rules: {
        name: [
          { required: true, message: '请输入ユーザー或邮箱', trigger: 'blur' },

        ],

        pass: [
          {required: true, validator: validatePass, trigger: 'blur' }
        ],
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {

          this.axios({
            method: 'post',

            url: 'index/user/login',
            data: {
              user:this.ruleForm.name,
              pwd:this.ruleForm.pass,


            },
            success: (res)=>{
              console.log(res,1032);
              if(res.data.msg ==="200"){
                let token =res.data.data.token

                this.setCookie("token", token);

                this.$router.push('/index')


              }else{
                this.$message({
                  type: 'error',
                  message: res.data.date,
                })
              }
            }
          } )
        } else {

          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
}

</script>
<style lang="less" scoped>
@import "./login.less";
// slot style
.in-index {
  color: var(--colMain);
  font-weight: 700;
}
</style>
