<template>
<div class="purchase">
  <h2>参加記録です</h2>
  <h2 v-if="purchaseList.length===0">まだ参加記録がありません,早く参加してください</h2>
  <div class="hot row" v-if="purchaseList.length!==0">
    <div class="col-6 col-md-4 col-lg-3" v-for="(item,index) in purchaseList" :key="index">
  <div class="card " >
    <img :src="item.image" class="card-img-top" alt="...">
    <div class="card-body">
      <h5 class="card-title ov-h">{{ item.title }}</h5>
      <h4>{{item.part_son}}回参加しました</h4>
      <h6> <span>参加期間番号です：</span>  <i class="col333">{{item.bh}}</i></h6>
      <button type="button" class="btn btn-primary" @click="handPush(item.bh,item.id)">詳細を調べます</button>
    </div>
  </div>
  </div>
</div>
</div>
</template>
<script>
export default {
  data(){
    return{
      purchaseList:[],
    }
  },created() {
    this.axios({
      method: 'post',
      url:'index/user/me_pate',
      data: {
       token:this.getCookie('token'),
        lang:'ja'
      },
      success: (res) => {

        this.purchaseList=res.data.date





      }
    })
  },methods:{
    handPush(bh,id){
      this.$router.push({
        path: '/publicity',
        query: {
          id,
          bh,
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.purchase{
  background-color: #fff;
  padding-top: 2rem;
  h2{
    font-size: 2rem;
    color: #000000;
    margin-top: 30px;
  }
  .hot{
    max-width: 1200px;
    margin: auto;
  }
  .card-body{
    padding: 20px  10px;
  }
  .card{
    margin-top: 1.5rem;
      img{
        width: 100%;
        height: 100%;
      }
    .progress{
      height: 0.8rem;
      font-size: 0.25rem;
    }
    h4{
      color: #ffcd39;
      margin-top: 10px;
    }
    h5{
      line-height: 1.5rem;
      margin-top: 10px;

      color: #000000;

    }
    h6{
      margin-top: 10px;
    }
    .btn{
      margin-top: 10px;

      width: 60%;
      background-color: #fff;
      color: var(--colSubMain1);
      border-radius: 0.25rem;
      border:1px solid var(--colSubMain1);
      font-weight: bold;
      font-size: 0.5rem;


    }
  }
  .ov-h{
    height: 3rem;
    display:-webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2;
    overflow:hidden;
    text-overflow:ellipsis;
  }
}
</style>