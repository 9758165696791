<template>
<div class="message fx">
  <div class="box">
    <ul class="con1" ref="con1" :class="{anim:animate===true}">
      <li v-for='(item,index) in messageList' :Key="index" >
        <img src="../../../../public/img/gongxi.png" alt="">

        <span>
          恭喜用户
      </span>
        <div class="name">{{item.user_name}}</div>
        <span>获得</span>
        <div class="prize">
        {{item.product_name}}
      </div>
      </li>
    </ul>
  </div>
</div>



</template>

<script>
export default {
  name: "message",
  data() {
    return {
      animate:false, //控制过渡动画是否开启
      messageList:['消息111','消息2222','消息333','消息4444'],//通知消息列表
    }
  },

  created() {
    this.axios({
      method: 'get',
      url:"index/Details/carousel?lang=ja",
      data: {

      },
      success: (res) => {
        this.messageList=res.data.data


      }
    })
    // 定时触发滚动函数
    setInterval(this.messages,2000)
  },
  methods:{
//通知消息滚动函数
    messages(){
      this.animate=true;    // 开启过渡动画
      setTimeout(()=>{
        this.messageList.push(this.messageList[0]);  // 将数组的第一个元素追加到数组最后面
        this.messageList.shift();               //然后削除数组的第一个元素
        this.animate=false;  // margin-top 为0 的时候取消过渡动画，实现无缝滚动
      },3500)
    },
  }


}
</script>

<style lang="less" scoped>
.message{
  width: 40%;
  background-color: var(--colSubMain2);
  margin: 2rem 0;
  max-width: 1400px;
  border-radius: 5px;
  min-width: 340px;

  img{
    width:1.6rem;
    height: 1.6rem;
  }
}
.box{
  width: 96%;
  height: 1.6rem;
  overflow: hidden;
  margin-left: 1.5%;


  border-radius: 0.213rem;
}
.anim{
  transition: all 0.5s;
  margin-top: -1.6rem;
}
.con1 li{
  width: 100%;
  list-style: none;
  line-height: 1.6rem;
    display: flex;
  color: var(--col333);
  height: 1.6rem;

  font-size: 0.053rem;
  .name{
    width: 10%;
  }
  .prize{
    width: 60%;
  }
  div{

    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow:ellipsis;
  }

}

</style>