<template>
<div class="record">
  <h2>チャージ記録です</h2>
  <h2 v-if="recordList.length===0">暂无チャージ記録です</h2>

  <div class="recharge  mb-3" v-for="(item,index) in recordList" :key="index" >
    <div class="bh">
        <h3>請求書番号です:{{item.czbh}}</h3>
        <h4>时间：{{ item.time }}</h4>
    </div>
    <div class="money">
      <h5>
        <i class="colffc">
          {{item.money}}

        </i>
        <span class="col333">
        を消費し

        </span>
          <em>
        {{item.gold}}

      </em>
        <span class="col333">
       を獲得します
      </span>
      </h5>
    </div>
  </div>
  </div>

</template>

<script>
export default {
  data(){
    return{
      recordList:[],
    }
  },
  created() {
    let token= this.getCookie('token')

    this.axios({
      method: 'post',

      url: 'index/user/user_charge',
      data: {
        token,
      },
      success: (res) => {
        this.recordList = res.data.date


      }
    })
  }
}
</script>

<style lang="less" scoped>
.record{
  padding-top: 2rem;
  background-color: #fff;
  h2{
    font-size: 2rem;
    color: #000000;
    margin-top: 30px;
  }
  p{
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    color: var(--colSubMain1);

  }
  .recharge{
  padding: 0.3rem 5%;
    max-width: 1400px;
    border-bottom: 1px solid var(--colSubMain2);
    display: flex;
    .bh{
      width: 20%;
    }
    .money{
      width: 80%;
      text-align: right;
    }
    h3{
      color: #000000;
      font-size: 0.8rem;
    }
    h4{
      font-size: 0.6rem;
      margin-top: 0.2rem;
    }
    h5{
      line-height: 2rem;
      font-size: 1.2rem;

      em{
        color: var(--colSubMain1);

      }

    }
  }

}
</style>