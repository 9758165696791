import Vue from 'vue'
import App from './App.vue'
import { mixins, Api } from './mixin'
import router from './router'
import installElementUI from './assets/element-ui'
import store from './store'
import bootstrapCss from './bootstrap/css/bootstrap.min.css'
import bootstrapJs from './bootstrap/js/bootstrap.bundle'

import '../public/css/global.css'

import { InfiniteScroll } from 'element-ui'
Vue.use(InfiniteScroll)

// add element-ui
installElementUI(Vue)
// add global api
Api.install.call(Vue)

Vue.config.productionTip = false

new Vue({
  router,
  bootstrapCss,
  bootstrapJs, 
  mixins,
  store,
  render: h => h(App),
}).$mount('#app')
