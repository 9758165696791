<template>
  <div id="app" class="app-page">
    <header-module v-if="show"></header-module>
    <router-view v-if="isRouterAlive" class="page" :show.sync="show" :isShowFooter.sync="isShowFooter" />
    <footer-module v-if="isShowFooter"></footer-module>
  </div>
</template>

<script>
import headerModule from "./components/header";
import footerModule from "./components/footer";


export default {
  name: "App",
  components: {headerModule, footerModule},
  data(){
    return {
      show: true,
      isRouterAlive: true,
      isShowFooter: true
    }
  },



};
</script>
<style lang="less">
#app {

  padding: 0;
  margin: 0 auto;

  height: 100%;
  overflow-x: hidden;
}
.page{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  padding-bottom: 30px;
  > div{
    width: 100%;
  }
}
</style>
