<template>

  <div class="hand col333">
    <main>
  <p class="h2">
    {{title1}}
  </p>
<div v-html=" problem">

</div>
      <p class="h2">
        {{title2}}
      </p>
  <div v-html=" recharge">

  </div>
    </main>

  </div>

</template>

<script>
export default {
  data(){
    return{
      problem:'',
      recharge:'',
      title1:'',
      title2:'',
    }
  },
  created() {
    this.axios({
      method: 'get',
      url:'index/index/problem?lang=en',
      data: {},
      success: (res) => {
      
        // this.problem=res.data.data[0].content
        let data=res.data.data
       if(data.length!==0){

         this.problem=data[0].content
          this.title1=data[0].title
       }
        

      }
    })
    this.axios({
      method: 'get',
      url:'index/index/recharge?lang=en',
      data: {},
      success: (res) => {
     
        let data=res.data.data
        if(data.length!==0) {
          this.recharge = data[0].content
          this.title2=data[0].title
        }



      }
    })
  }
}
</script>

<style lang="less" scoped>

.hand{
  background-color: #fff;

  main{
    background-color: #fff;
    width: 80%;
    .h2{
      margin: 30px 0;
    }
  }
}
</style>